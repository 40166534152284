import { getCollectChequeArchiveColumns, getCollectChequeArchiveRows } from "./collect-cheque-statement-archive";
import { getCollectChequeArchive } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportCollectChequeArchive({ activeCompany }) {
  return (
    <DynamicReport
      reportName="rent-roll"
      activeCompany={activeCompany}
      columnsDataProvider={getCollectChequeArchiveColumns}
      rowsDataProvider={getCollectChequeArchiveRows}
      fetchData={getCollectChequeArchive}
      exportUrl="reports/export-query-report/collect-cheque-archive"
      filtersInitialState={{}}
      orderBy="CollectionDate"
      showDateRangeFilter={false}
      showDateFilter
    />
  );
}
