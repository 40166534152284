/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { camelize, pascalize, camelizeKeys } from "humps";
import {
  addDays, format, isWeekend, subYears
} from "date-fns";
import { isEmpty, kebabCase } from "lodash";
import qs from "qs";
import { Checkbox, FormControl, TextArea } from "@hydra/atom/components";

import {
  selectObjectFields,
  selectRelationalFields,
} from "@/reducers/dynamic/dynamicObjectFieldFormReducer";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";
import { objectWithFullNameFields, statusFieldNames } from "@/utils/dynamic/constants";
import {
  checkIfAttachmentIsImage,
  getAttachmentUrl,
  formatCurrency,
  getFullName,
  formatDecimalValues,
  formatDate,
} from "@/utils/helpers";
import { handleDrawer } from "@/utils/modal/helpers";
import appSettings from "@/settings";

import {
  CustomizedTimePicker,
  CustomizedDatePicker,
  CustomizedDateTimePicker,
  CustomizedDateRangePicker,
  PhoneNumberSelector,
  TextEditor,
  FileUploader,
  Status,
  StatusCircle,
  RadioWithIcon,
} from "@/components/common";
import {
  FieldLabel,
  LookupField,
  SelectField,
  InputField,
  GeolocationField,
  PolymorphicField,
  RelationalField,
} from "@/components/dynamic/fields";
import {
  formatGLAccountOption,
  formatBankOption,
  filterClearChequePaymentAccountOption,
  filterTaxRuleOption,
} from "@/utils/finance/helpers";
import { filterSupervisorPettyCashRequestEmployee } from "@/utils/facility/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { getRoles } from "@/api/user/authApi";
import {
  uploadAttachmentWithDynamicObjectRecord,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDateFormat, getTestId, formatApiPayloadDate } from "../helpers";
import { validationMessages } from "./constants";
import { smFieldColumnsMap, mdFieldColumnsMap } from "./maps";

export const getObjectNameFromId = (id) => {
  const str = id.split("-");
  const objectName = str.slice(0, str.length - 1).join("-");

  return objectName;
};

export const prepareObjectFieldData = (fields, isRelationalField = false) => {
  const fieldsData = [];

  let objectFields;

  if (!isRelationalField) {
    objectFields = selectObjectFields({ fields });
  } else {
    objectFields = fields;
  }

  objectFields.forEach((fieldData) => {
    switch (fieldData.fieldType.toLowerCase()) {
      case "text":
      case "barcode":
        fieldData.minimumLength = fieldData.minimum || null;
        fieldData.maximumLength = fieldData.maximum || null;
        break;

      case "number":
      case "money":
        fieldData.minimum = Number(fieldData.minimum) || null;
        fieldData.maximum = Number(fieldData.maximum) || null;
        fieldData.decimalPlaces = Number(fieldData.decimalPlaces) || null;
        break;

      case "select":
        fieldData.options = fieldData.selectFieldOptions;
        break;

      case "geolocation":
        fieldData.displayFormat = fieldData.geoLocationDisplayFormat;
        break;

      case "lookup":
        fieldData.lookupObjectKey = fieldData.objectName.key;
        fieldData.lookupObjectName = fieldData.objectName.value;
        fieldData.lookupFieldName = "Key";
        fieldData.lookupDisplayFieldName = fieldData.lookupField.map((o) => o.value);
        break;

        // TODO: Add support for polymorphic field

      case "table": {
        const additionalFields = selectRelationalFields({ fields }, fieldData.id);
        fieldData.fields = prepareObjectFieldData(additionalFields, true);
        break;
      }

      case "date":
        fieldData.displayFormat = fieldData.dateDisplayFormat.value;
        fieldData.minimumDate = fieldData.minimumDate ?
          formatApiPayloadDate(new Date(fieldData.minimumDate)) :
          null;
        fieldData.maximumDate = fieldData.maximumDate ?
          formatApiPayloadDate(new Date(fieldData.maximumDate)) :
          null;
        break;

      case "datetime":
        fieldData.minimumDate = fieldData.minimumDate ?
          format(new Date(fieldData.minimumDate), "yyyy-MM-dd hh:mm:ss") :
          null;
        fieldData.maximumDate = fieldData.maximumDate ?
          format(new Date(fieldData.maximumDate), "yyyy-MM-dd hh:mm:ss") :
          null;
        break;

      case "attachment":
        fieldData.allowedFileTypes = fieldData.allowedFileTypes
          .filter((type) => type)
          .map(({ value: fileTypeValue }) => fileTypeValue);
        break;

      default:
        break;
    }

    fieldData.objectFieldType = pascalize(fieldData.fieldType);
    fieldData.name = fieldData.fieldName;
    fieldData.dataType = `${fieldData.objectFieldType}Field`;
    fieldData.unique = fieldData.isUnique || false;
    fieldData.required = fieldData.isRequired || false;
    fieldData.indexed = fieldData.isIndexed || false;
    fieldData.hidden = fieldData.hidden || false;
    fieldData.defaultValue = fieldData.defaultValue || null;

    fieldsData.push(fieldData);
  });

  return fieldsData;
};

const getAttachmentUrlWithoutBaseUrl = (url) => {
  if (url.startsWith(appSettings.minioBaseUrl)) {
    return url.split(appSettings.minioBaseUrl)[1];
  }

  return url;
};

export const prepareFieldValue = (field, fieldState, isEditing = false, filterValue = false) => {
  const { objectFieldType, name: fieldName } = field;
  switch (objectFieldType.toLowerCase()) {
    case "lookup": {
      if (field.allowMultiple) {
        if (fieldState[fieldName]) {
          return fieldState[fieldName].map((v) => v.value);
        }

        return [];
      }

      if (fieldState[fieldName]?.id) {
        return fieldState[fieldName].id;
      }

      if (fieldState[fieldName]?.value) {
        return fieldState[fieldName].value;
      }

      return "";
    }

    case "polymorphic": {
      if (field.allowMultiple) {
        if (fieldState[fieldName].length) {
          const value = fieldState[fieldName].map((v) => v.value);

          if (filterValue) {
            return {
              key: value,
            };
          }

          return {
            key: value,
            objectName: fieldState[fieldName][0].lookupObjectName,
          };
        }

        return "";
      }

      if (fieldState[fieldName]?.value) {
        if (filterValue) {
          return {
            key: fieldState[fieldName].value,
          };
        }

        return {
          key: fieldState[fieldName].value,
          objectName: fieldState[fieldName].lookupObjectName,
        };
      }

      return "";
    }

    case "number":
    case "money":
      if (!fieldState[fieldName] && fieldState[fieldName] !== 0) {
        return null;
      }

      return Number(fieldState[fieldName]);

    case "geolocation":
      return `${fieldState[`${fieldName}Lat`]}, ${fieldState[`${fieldName}Lng`]}`;

    case "date": {
      if (fieldState[fieldName]) {
        return formatApiPayloadDate(new Date(fieldState[fieldName]));
      }

      return null;
    }

    case "time":
      if (fieldState[fieldName]?.length < 6) {
        if (!fieldState[fieldName]) {
          return "";
        }

        return `${fieldState[fieldName]}:00`;
      }
      break;

    case "datetime": {
      if (fieldState[fieldName]) {
        return format(new Date(fieldState[fieldName]), "yyyy-MM-dd hh:mm:ss");
      }

      return null;
    }

    case "table":
      return [];

    case "checkbox":
      if (fieldState[fieldName]) return fieldState[fieldName];

      return false;

    case "attachment":
      if (fieldState[fieldName] === undefined) return [];

      if (isEditing) {
        return fieldState[fieldName].map((attachment) =>
          getAttachmentUrlWithoutBaseUrl(attachment.url)
        );
      }

      return [];

    case "select":
      if (field.allowMultiple) {
        if (fieldState[fieldName]) {
          return fieldState[fieldName].map((v) => v.value);
        }
      }

      if (fieldState[fieldName]?.value) {
        return fieldState[fieldName].value;
      }

      return null;

    default:
      break;
  }

  if (fieldState[fieldName] === undefined) return "";

  return fieldState[fieldName];
};

export const camelizeObjectFieldNames = (fields) =>
  fields.map((field) => {
    switch (field.objectFieldType.toLowerCase()) {
      case "lookup":
        field.lookupDisplayFieldName = field.lookupDisplayFieldName.map((fieldName) =>
          camelize(fieldName)
        );
        break;

      case "polymorphic":
        field.lookups = field.lookups.map((lookup) => {
          lookup.lookupDisplayFieldName = lookup.lookupDisplayFieldName.map((fieldName) =>
            camelize(fieldName)
          );

          return lookup;
        });
        break;

      case "table": {
        const camelizedFields = camelizeObjectFieldNames(field.fields);
        field.fields = camelizedFields;
        break;
      }

      default:
        break;
    }

    field.camelizedName = camelize(field.name);
    field.name = field.camelizedName;

    return field;
  });

export const mapObjectFieldTypeIcon = (value) => {
  switch (value) {
    case "checkbox":
      return "check-icon-2";
    case "detailkey":
    case "lookup":
    case "uom":
    case "polymorphic":
      return "layers-icon";
    case "money":
    case "autonumber":
    case "number":
    case "text":
    case "barcode":
      return "scan-icon";
    case "date":
    case "datetime":
      return "calendar-icon";
    case "time":
      return "clock-icon";
    case "url":
      return "link-icon";

    default:
      return `${value}-icon`;
  }
};

export const prepareFieldState = ({ field, value, prepareRelationalField }) => {
  switch (field.objectFieldType.toLowerCase()) {
    case "lookup": {
      if (!value || isEmpty(value)) {
        return null;
      }

      if (Array.isArray(value)) {
        return value.map((r) => {
          let label = "";

          if (objectWithFullNameFields.includes(field.lookupObjectName)) {
            label = getFullName(r);
          } else {
            label = r[field.lookupDisplayFieldName[0]];
          }

          return {
            ...r,
            value: r.id,
            label,
          };
        });
      }

      if (objectWithFullNameFields.includes(field.lookupObjectName)) {
        const label = getFullName(value);
        return {
          ...value,
          value: value.id,
          label,
        };
      }

      return {
        ...value,
        value: value.id,
        label: value[field.lookupDisplayFieldName[0]],
      };
    }

    case "polymorphic": {
      if (!value || isEmpty(value)) {
        return null;
      }

      const fieldValue = value;
      let lookupObjectName;

      if (Array.isArray(fieldValue)) {
        lookupObjectName = value[0].objectName;
      } else {
        lookupObjectName = value.objectName;
      }

      const selectedLookup = field.lookups.find(
        (lookup) => lookup.lookupObjectName === lookupObjectName
      );
      const displayField = selectedLookup.lookupDisplayFieldName[0];

      if (Array.isArray(fieldValue)) {
        return fieldValue.map((r) => {
          let labelValue = "";

          if (objectWithFullNameFields.includes(lookupObjectName)) {
            labelValue = getFullName(fieldValue);
          } else {
            labelValue = r[displayField];
          }

          return {
            ...r,
            lookupObjectName,
            value: r.id,
            label: labelValue,
          };
        });
      }

      let label = "";

      if (objectWithFullNameFields.includes(lookupObjectName)) {
        label = getFullName(fieldValue);
      } else {
        label = fieldValue[displayField];
      }

      return {
        ...fieldValue,
        lookupObjectName,
        value: fieldValue.id,
        label,
      };
    }

    case "table": {
      if (prepareRelationalField) {
        const tableFieldState = value.map((record) => {
          // eslint-disable-next-line no-use-before-define
          const recordState = prepareDynamicObjectState(record, field.fields);
          recordState.detailId = record.detailId;
          return recordState;
        });
        return tableFieldState;
      }

      break;
    }

    case "select": {
      if (field.allowMultiple) {
        if (value) {
          let multipleValue;
          if (typeof value === "string") {
            if (!value.startsWith("[")) {
              multipleValue = [value];
            } else multipleValue = JSON.parse(value);
          }

          const selectedOptions = field.options.filter((o) => multipleValue?.includes(o.value));
          return selectedOptions;
        }
      }

      if (value) {
        const selectedOption = field.options.find((o) => o.value === value);

        return selectedOption;
      }

      return null;
    }

    case "geolocation": {
      if (value) {
        const [lat, lng] = value.split(",");
        return {
          lat,
          lng,
        };
      }

      return {
        lat: "",
        lng: "",
      };
    }

    case "date": {
      if (value) return new Date(value);
      return null;
    }

    case "datetime": {
      if (value) return new Date(`${value}Z`);

      return null;
    }

    case "attachment": {
      if (!value) {
        return [];
      }

      let parsedValue;

      try {
        parsedValue = JSON.parse(value);
      } catch (error) {
        parsedValue = value;
      }

      if (Array.isArray(parsedValue)) {
        return parsedValue.map((attachmentUrl) => ({
          url: getAttachmentUrl(attachmentUrl),
          isImage: checkIfAttachmentIsImage(attachmentUrl),
        }));
      }

      return [];
    }

    default:
      return value;
  }
};

export const prepareDynamicObjectState = (record, fields, prepareRelationalField = false) => {
  const recordData = {};

  fields.forEach((field) => {
    recordData[camelize(field.name)] = prepareFieldState({
      field,
      value: record[camelize(field.name)],
      prepareRelationalField,
    });
  });
  return recordData;
};

export const prepareDynamicObjectInitialState = (fields) => {
  const initialState = {};

  fields.forEach((field) => {
    switch (field.objectFieldType.toLowerCase()) {
      case "number":
      case "money":
      case "text":
      case "autonumber":
      case "area":
      case "percentage":
        initialState[field.name] = "";
        break;

      case "geolocation":
        initialState[field.name] = {
          lat: "",
          lng: "",
        };
        break;

      default:
        initialState[field.name] = null;
        break;
    }
  });

  return initialState;
};

export const prepareDynamicObjectDefaultValues = (fields) => {
  const defaultValues = {};

  fields?.forEach(({ camelizedName: fieldName, objectFieldType, ...field }) => {
    switch (objectFieldType.toLowerCase()) {
      case "table": {
        const initialState = prepareDynamicObjectInitialState(field.fields);
        const defaultFieldValues = prepareDynamicObjectDefaultValues(field.fields);
        const key = `${fieldName}${objectFieldType}`;
        defaultValues[key] = {
          ...initialState,
          ...defaultFieldValues,
        };
        break;
      }

      case "select": {
        const defaultOption = field.options.find((o) => o.default);

        if (defaultOption) {
          if (statusFieldNames.includes(fieldName)) {
            defaultOption.color = statusColorMap.get(defaultOption.value.toLowerCase());
          }

          defaultValues[fieldName] = defaultOption;
        }

        break;
      }

      case "date": {
        if (field.defaultValue === "@current") {
          defaultValues[fieldName] = new Date();
        }

        break;
      }

      case "time": {
        if (field.defaultValue) {
          defaultValues[fieldName] = field.defaultValue;
        }

        break;
      }

      case "money": {
        if (field.defaultValue) {
          defaultValues[fieldName] = formatDecimalValues(field.defaultValue);
        }

        break;
      }

      default:
        break;
    }
  });

  return defaultValues;
};

export const prepareDynamicObjectData = (fields, state, isEditing) => {
  const recordData = {};

  fields.forEach((field) => {
    recordData[field.name] = prepareFieldValue(field, state, isEditing);
  });

  return recordData;
};

export const checkForEmptyFieldValues = (fields, state) =>
  fields.some((f) => !isEmpty(state[f.name]));

export const getFieldColumnClassName = ({ field, fieldLayout = null, row = null }) => {
  let className = "";

  let fieldType = field.objectFieldType.toLowerCase();

  if (fieldType === "text") {
    if (field.multiLine) {
      fieldType = "multiline";
    }

    if (field.richText) {
      fieldType = "richtext";
    }
  }

  if (!fieldLayout || !row) {
    className = `col-sm-${smFieldColumnsMap.get(fieldType)} col-md-${mdFieldColumnsMap.get(
      fieldType
    )}`;

    return className;
  }

  if (typeof row.layoutType === "string" && row?.layoutType?.toLowerCase() === "equal") {
    className = "col";

    return className;
  }

  switch (fieldType) {
    case "attachment":
    case "checkbox":
    case "multiline":
    case "richtext":
      className = "col-12";
      break;

    default:
      className = `col-sm-${smFieldColumnsMap.get(fieldType)} col-md-${fieldLayout.columns}`;
      break;
  }

  return className;
};

export const prepareValidationRulesAndMessages = (field) => {
  let rules = [];
  const messages = {};

  if (field.required && field.objectFieldType !== "Checkbox") {
    rules.push("required");
    messages.required = validationMessages.required;
  }

  if (field.required && field.objectFieldType === "Checkbox") {
    rules.push("accepted");
    messages.accepted = validationMessages.accepted;
  }

  if (field.minimumLength) {
    rules.push(`min:${field.minimumLength}`);
    messages.min = `The value must be at least ${field.maximumLength} characters`;
  }

  if (field.minimum) {
    rules.push(`min:${field.minimum},num`);
    messages.min = `The value must be greater than or equal to ${field.minimum}`;
  }

  if (field.maximumLength) {
    rules.push(`max:${field.maximumLength}`);
    messages.max = `The value must be less than ${field.maximumLength} characters`;
  }

  if (field.maximum) {
    rules.push(`max:${field.maximum},num`);
    messages.max = `The value must be less than or equal to ${field.maximum}`;
  }

  // if (field.decimalPlaces) {
  //   // const regex = `^[0-9]*(\\.[0-9]{${field.decimalPlaces}})?$`;
  //   const regex = '^\\d+(\\.\\d{1,2})?$';
  //   rules.push(`regex:${regex}`);
  //   messages.regex = `Decimal places cannot be more than ${field.decimalPlaces}`;
  // }

  if (field.objectFieldType === "Email") {
    rules.push("email");
    messages.email = validationMessages.email;
  }

  if (field.objectFieldType === "Url") {
    rules.push("url");
    messages.url = validationMessages.url;
  }

  if (field.objectFieldType === "Percentage") {
    rules.push("min:0,num");
    messages.min = "The value must be greater than 0";
    if (field.name !== "rentChangePercentage") {
      rules.push("max:100,num");
      messages.max = "The value must be less than or equal to 100";
    }
  }

  rules = rules.join("|");

  return { rules, messages };
};

export const getSelectedFormLayout = ({ forms, isEditing, selectedLayout }) => {
  let isTabLayout = false;

  if (!forms || !forms.length) {
    return {
      layout: null,
      isTabLayout,
    };
  }

  if (selectedLayout) {
    const selectedFormLayout = forms.find(
      (f) => f.dataType === selectedLayout || f.name === selectedLayout
    );

    if (selectedFormLayout) {
      return {
        layout: selectedFormLayout,
      };
    }
  }

  if (isEditing) {
    const editFormLayout = forms.find((f) => f.dataType === "UpdateFormModel");

    if (editFormLayout) {
      isTabLayout = editFormLayout.elements.some((f) => f.dataType === "TabElement");

      return { layout: editFormLayout, isTabLayout };
    }
  }

  const saveFormLayout = forms.find((f) => f.dataType === "CreateFormModel");

  if (saveFormLayout) {
    isTabLayout = saveFormLayout.elements.some((f) => f.dataType === "TabElement");

    return { layout: saveFormLayout, isTabLayout };
  }

  return { layout: null, isTabLayout: false };
};

export const evaluateRule = (rule, state = {}, isChild = false, childState = {}) => {
  let { condition: expression } = rule;
  const { propertyName } = rule;
  // Replaces all field names in expression with state values
  Array.from(expression.matchAll(/{(\w+)}/g)).forEach((match) => {
    const key = match[0];
    const fieldName = camelize(match[1]);
    let expressionValue =
      isChild && Boolean(childState) && Object.prototype.hasOwnProperty.call(childState, fieldName) ?
        childState[fieldName] :
        state[fieldName];

    if (expressionValue && typeof expressionValue === "object") {
      expressionValue =
        isChild &&
        Boolean(childState) &&
        Object.prototype.hasOwnProperty.call(childState, fieldName) ?
          childState[fieldName]?.value :
          state[fieldName]?.value;
    }

    if (typeof expressionValue === "boolean") {
      const substituteValue = expressionValue ? "True" : "False";
      expression = expression.replaceAll(key, `'${substituteValue}'`);
    } else {
      expression = expression.replaceAll(key, `'${expressionValue}'`);
    }
  });

  // Replaces all roles in expression with user roles
  expression = expression.replace(/{@role}[\s=]*'([\w\d]+)'/g, (_, roleToMatch) => {
    const roles = getRoles();

    if (roles.includes(roleToMatch)) {
      return true;
    }

    return false;
  });

  if (expression.includes("}")) {
    return false;
  }

  expression = expression.replaceAll("=", "===");

  const regex = /'([^']*)'\s*===\s*'(.*)'/;

  // Extract the captured parts of the string
  const match = expression.match(regex);

  let result = false;

  if (match) {
    const leftSide = match[1];
    const rightSide = match[2];

    const conditions = rightSide.split(" || ");

    // Check if any condition on the right-hand side is true
    for (const condition of conditions) {
      // eslint-disable-next-line no-eval
      if (eval(`'${leftSide}' === '${condition}'`)) {
        result = true;
        break;
      }
    }
  } else {
    // If the expression does not match the pattern, evaluate as is
    // eslint-disable-next-line no-eval
    result = eval(expression);
  }

  if (propertyName === "show") {
    return result;
  }

  if (propertyName === "hide") {
    return !result;
  }

  return result;
};

function addWorkingDays(date, daysToAdd) {
  let count = 0;
  let newDate = date;

  while (count < daysToAdd) {
    newDate = addDays(newDate, 1);
    if (!isWeekend(newDate)) {
      count += 1;
    }
  }

  return newDate;
}

const checkDate = (fieldItem, itemState, onChange, isMaxDate, objectName) => {
  const dateProp = isMaxDate ? "maximumDate" : "minimumDate";
  const currentDateProp = isMaxDate ? "camelizedName" : "camelizedName";

  if (fieldItem[dateProp] === "@current") {
    return new Date();
  }

  if (
    fieldItem[dateProp] === "@ageLimit" &&
    (!itemState[fieldItem[currentDateProp]] ||
      new Date(itemState[fieldItem[currentDateProp]]) > new Date())
  ) {
    return subYears(new Date(), 18);
  }

  if (
    fieldItem[dateProp] &&
    itemState &&
    Object.prototype.hasOwnProperty.call(itemState, camelize(fieldItem[dateProp]))
  ) {
    const date1 = new Date(itemState[fieldItem[currentDateProp]]);
    const date2 = new Date(itemState[camelize(fieldItem[dateProp])]);

    if (objectName === dynamicObjectMap.get("ReservationObjectName") && isMaxDate) {
      const daysToAdd = 4;
      const resultDate = addWorkingDays(itemState[camelize(fieldItem[dateProp])], daysToAdd);
      return resultDate;
    }
    if (objectName === dynamicObjectMap.get("BlanketAgreementObjectName") && !isMaxDate) {
      const resultDate = addDays(itemState[camelize(fieldItem[dateProp])], 2);
      return resultDate;
    }

    if ((isMaxDate && date1 > date2) || (!isMaxDate && date1 < date2)) {
      onChange(fieldItem[currentDateProp], date2);
    }

    return itemState[camelize(fieldItem[dateProp])];
  }

  return null;
};

export const checkRules = (fieldItem, state, isChildField = false, parentState = {}) => {
  if (!fieldItem?.rules) {
    return true;
  }

  const rule = fieldItem?.rules.find((r) => r.propertyName === "show" || r.propertyName === "hide");

  if (rule) {
    if (isChildField) {
      return evaluateRule(rule, parentState, isChildField, state);
    }

    return evaluateRule(rule, state);
  }

  return true;
};

const renderAttachment = (file) => {
  if (file?.file) {
    return file.file.type.toLowerCase().endsWith("pdf") ? PdfFileIcon : file.url;
  }
  return file.url.toLowerCase().endsWith("pdf") ? PdfFileIcon : getAttachmentUrl(file.url);
};

function ImgCell({ value }) {
  return value.map((item) => (
    <a key={item.url} target="_blank" href={item.file ? item.url : getAttachmentUrl(item.url)}>
      <div className="table-img-container">
        <img loading="lazy" src={renderAttachment(item)} alt="img" />
        {value?.name}
      </div>
    </a>
  ));
}

function TruncatedTextCell({ value, type = "default" }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(false);
    }
  };

  const displayText =
    (value?.split(" ").slice(0, 2).join(" ") || "") + (value?.split(" ").length > 2 ? "..." : "");

  return (
    <div
      className="truncated-text-cell"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {type === "default" ? (
        <p className="text">{displayText}</p>
      ) : (
        <a className="text">{displayText}</a>
      )}
      {isHovered && <div className="tooltip">{value}</div>}
    </div>
  );
}

function LookupCell({ value, column }) {
  if (!value) return <div />;
  const { isStaticObject } = column;
  return value?.value ? (
    <div className="link-cell">
      <Link
        to={handleDrawer(
          value.value,
          null,
          false,
          isStaticObject ? "staticObjectDrawers" : "dynamicObjectDrawers"
        )}
      >
        <div className="truncated-text-cell">
          <a>
            <TruncatedTextCell value={value.label} type="lookup" />
          </a>
        </div>
      </Link>
    </div>
  ) : (
    <TruncatedTextCell value={value} />
  );
}

function PettyCashTransactionLookupCell({ value }) {
  const { data: transactionData, isInitialLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("PettyCashTransactionObjectName")), value],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("PettyCashTransactionObjectName"), value),
    {
      enabled: Boolean(value),
    }
  );

  if (isInitialLoading) return <div />;

  if (!transactionData?.childTransaction) return <div />;

  return (
    <div className="link-cell">
      <Link
        to={handleDrawer(transactionData?.childTransaction, null, false, "dynamicObjectDrawers")}
      >
        View attachments
      </Link>
    </div>
  );
}

PettyCashTransactionLookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PettyCashTransactionLookupCell.defaultProps = {
  value: "",
};

export const formatDynamicObjectRecord = ({ objectSchema, data, defaultCurrencyCode = "AED" }) => {
  const recordState = prepareDynamicObjectState(data, objectSchema?.document, true);
  const recordData = [];
  const tableData = [];
  const attachmentData = [];
  const textAreaData = [];
  const richTextData = [];
  objectSchema?.document
    .filter(
      (f) =>
        !f.hidden &&
        !f.multiLine &&
        !f.richText &&
        recordState[f.camelizedName] &&
        f.label !== "PlaceId" &&
        f.name !== "detailId" &&
        !["table", "attachment"].includes(f.objectFieldType.toLowerCase()) &&
        f.objectFieldType.toLowerCase() !== "qrcode"
    )
    .forEach((field) => {
      const {
        objectFieldType, camelizedName: name, label, dataType
      } = field;

      const fieldValue = {
        fieldType: objectFieldType,
        fieldName: field.camelizedName,
        label,
        value: "-",
      };

      switch (objectFieldType.toLowerCase()) {
        case "lookup":
        case "polymorphic":
          fieldValue.isStaticObject = field.isStaticObject;

          if (field.allowMultiple) {
            fieldValue.value = recordState[name].map((v) => v.label).join(", ");
            fieldValue.values = recordState[name];
            fieldValue.allowMultiple = field.allowMultiple;
          } else {
            fieldValue.value = recordState[name].label;
            fieldValue.id = recordState[name].value;
          }
          break;

        case "select":
          if (field.allowMultiple) {
            fieldValue.value = recordState[name].map((v) => v.label).join(", ");
          } else {
            fieldValue.value = recordState[name].label;
          }
          break;

        case "date":
          fieldValue.value = formatDate(new Date(recordState[name]));
          break;

        case "datetime":
          fieldValue.value = format(new Date(recordState[name]), "dd/MM/yyyy hh:mm a");
          break;

        case "money":
          fieldValue.value = formatCurrency(recordState[name], defaultCurrencyCode);
          break;

        case "percentage":
          fieldValue.value = `${recordState[name]}%`;
          break;

        case "area":
          fieldValue.value = `${recordState[name]} sq. m`;
          break;

        case "checkbox":
          fieldValue.value = recordState[name] ? "Yes" : "No";
          break;

        default:
          if (dataType === "AddressField" && ["Street", "Country"].includes(label)) {
            try {
              const street = JSON.parse(recordState[name]);
              fieldValue.value = street.label;
              break;
            } catch (error) {
              break;
            }
          }

          fieldValue.value = recordState[name];
          break;
      }
      recordData.push(fieldValue);
    });

  const relationalFields = objectSchema?.document.filter(
    (f) => f.objectFieldType.toLowerCase() === "table"
  );

  if (
    relationalFields.length &&
    objectSchema?.objectName !== dynamicObjectMap.get("PettyCashObjectName")
  ) {
    relationalFields.forEach((field) => {
      const columns = field.fields
        .filter((f) => {
          if (f.camelizedName === "pettyCashTransactionId") {
            return true;
          }

          if (f.hidden) {
            return false;
          }

          if (f.richText) {
            return false;
          }

          return true;
        })
        .map((f) => {
          if (f.objectFieldType === "Attachment") {
            return {
              Header: f.label,
              accessor: f.camelizedName,
              lookupObjectName: f.lookupObjectName,
              objectFieldType: f.objectFieldType,
              Cell: ImgCell,
            };
          }

          if (f.objectFieldType === "Lookup") {
            return {
              Header: f.label,
              accessor: f.camelizedName,
              lookupObjectName: f.lookupObjectName,
              objectFieldType: f.objectFieldType,
              allowMultiple: f.allowMultiple,
              isStaticObject: f.isStaticObject,
              Cell: LookupCell,
            };
          }

          if (f.camelizedName === "pettyCashTransactionId") {
            return {
              Header: "Attachment",
              accessor: "pettyCashTransactionId",
              Cell: PettyCashTransactionLookupCell,
              objectFieldType: f.objectFieldType,
              allowMultiple: f.allowMultiple,
            };
          }

          return {
            Header: f.label,
            accessor: f.camelizedName,
            lookupObjectName: f.lookupObjectName,
            objectFieldType: f.objectFieldType,
            allowMultiple: f.allowMultiple,
          };
        });

      const fieldData = recordState[field.camelizedName].map((row) => {
        const dataObject = {};
        columns.forEach((column) => {
          const { accessor, objectFieldType, allowMultiple } = column;

          if (!row[accessor] && objectFieldType.toLowerCase() !== "checkbox") {
            dataObject[accessor] = "";
          } else {
            switch (objectFieldType.toLowerCase()) {
              case "lookup":
                if (allowMultiple) {
                  dataObject[accessor] = row[accessor].map((v) => v.label).join(", ");
                } else {
                  dataObject[accessor] = row[accessor];
                }
                break;
              case "polymorphic":
              case "select":
                if (allowMultiple) {
                  dataObject[accessor] = row[accessor].map((v) => v.label).join(", ");
                } else {
                  dataObject[accessor] = row[accessor].label;
                }
                break;

              case "date":
                dataObject[accessor] = formatDate(new Date(row[accessor]));
                break;

              case "datetime":
                dataObject[accessor] = format(new Date(row[accessor]), "dd/MM/yyyy hh:mm a");
                break;

              case "money":
                dataObject[accessor] = formatCurrency(row[accessor], defaultCurrencyCode);
                break;

              case "attachment":
                dataObject[accessor] = row[accessor];
                break;

              case "checkbox":
                dataObject[accessor] = row[accessor] ? "Yes" : "No";
                break;

              default:
                dataObject[accessor] = row[accessor];
                break;
            }
          }
        });

        return dataObject;
      });
      if (fieldData.length) {
        tableData.push({
          fieldName: field.camelizedName,
          label: field.label,
          columns,
          data: fieldData,
        });
      }
    });
  }

  const attachmentFields = objectSchema?.document.filter(
    (f) => f.objectFieldType.toLowerCase() === "attachment"
  );

  if (attachmentFields.length) {
    attachmentFields.forEach((f) => {
      if (recordState[f.camelizedName].length) {
        attachmentData.push({
          label: f.label,
          value: recordState[f.camelizedName],
        });
      }
    });
  }

  const textAreaFields = objectSchema?.document.filter((f) => f.multiLine);

  if (textAreaFields.length) {
    textAreaFields.forEach((f) => {
      if (recordState[f.camelizedName]) {
        textAreaData.push({
          label: f.label,
          value: recordState[f.camelizedName],
        });
      }
    });
  }

  const richTextFields = objectSchema?.document.filter((f) => f.richText);

  if (richTextFields.length) {
    richTextFields.forEach((f) => {
      if (recordState[f.camelizedName]) {
        richTextData.push({
          label: f.label,
          value: recordState[f.camelizedName],
        });
      }
    });
  }
  return {
    recordData,
    tableData,
    attachmentData,
    textAreaData,
    richTextData,
  };
};

export const getDynamicObjectName = (objectName, data) => {
  if (objectWithFullNameFields.includes(objectName)) {
    const fullName = getFullName(data);
    return fullName;
  }

  if (objectName === "Item") {
    return data?.itemName;
  }

  if (data?.name) return data.name;

  if (data?.number) return data.number;

  return pascalize(objectName);
};

export const generateTestIdForDynamicField = ({ fieldName, fieldType, objectName }) =>
  getTestId(`${pascalize(objectName)}-${pascalize(fieldName)}-${pascalize(fieldType)}`);

const assignParentAndChildFieldNames = (field, lookupParentFields) => {
  let parentFieldName;
  let childFieldName;
  if (field?.lookupFieldParent?.length > 0) {
    parentFieldName = field?.lookupFieldParent[0]?.parentField;
    childFieldName = field.name;
    return { parentFieldName, childFieldName };
  }
  parentFieldName = field.name;
  const matchingParentField = lookupParentFields?.find((parentField) =>
    parentField?.lookupFieldParent?.some(
      (obj) => obj?.parentField.toLowerCase() === field.name.toLowerCase()
    )
  );
  if (matchingParentField) {
    childFieldName = matchingParentField.name;
  }
  return { parentFieldName, childFieldName };
};

function extractWordsBeforeRequired(array) {
  const result = [];

  array.forEach((str) => {
    const index = str.indexOf(" is required");
    if (index !== -1) {
      const words = camelize(str.substring(0, index));
      result.push(words);
    }
  });

  return result;
}

export function checkWordMatch(word, keyArray) {
  const wordsData = extractWordsBeforeRequired(keyArray);
  for (let i = 0; i < wordsData.length; i += 1) {
    const words = wordsData[i];
    if (words.includes(word)) {
      return true;
    }
  }
  return false;
}

export const renderField = ({
  field,
  parentObjectName,
  parentFieldName,
  state,
  onChange,
  parentId,
  parentState,
  errors = [],
  onChildStateChange,
  onChildFieldBlur,
  relationalFieldState = [],
  showLabel = true,
  isChildField = false,
  isDisabled = false,
  fieldLayout = null,
  isInsideModal = false,
  objectData = null,
  childParentId = null,
  autoFocusElement = null,
  company = null,
  user = null,
  updateTableDataOnSubmit = false
}) => {
  const { parentFieldName: parent, childFieldName } = assignParentAndChildFieldNames(
    field,
    objectData?.document
  );
  field.parentFieldName = parent;
  field.childFieldName = childFieldName;

  const autoFocusState = field.name.toLowerCase() === autoFocusElement?.name?.toLowerCase();
  let isEditing = false;
  if (!isChildField) {
    isEditing = Boolean(parentId);
  } else {
    isEditing = Boolean(childParentId);
  }

  let isFieldDisabled = isDisabled || field.readOnly;

  if (isEditing && field.static && !isFieldDisabled) {
    isFieldDisabled = true;
  }

  // TODO: move to ContractForm
  if (
    parentObjectName === dynamicObjectMap.get("ContractObjectName") &&
    state?.durationType?.value === "Default"
  ) {
    const readOnlyFields = ["agreementEndDate"];

    if (readOnlyFields.includes(field.name)) {
      isFieldDisabled = true;
    }
  }

  // TODO: move to ContractForm
  if (
    parentObjectName === dynamicObjectMap.get("ContractObjectName") &&
    state?.status?.value === "Active"
  ) {
    const roles = getRoles();

    const readOnlyFields = [
      "durationType",
      "gracePeriod",
      "duration",
      "noOfDuration",
      "gracePeriodType",
      "agreementStartDate",
      "agreementEndDate",
    ];

    if (readOnlyFields.includes(field.name) && !roles.includes("admin")) {
      isFieldDisabled = true;
    }
  }

  // Conditionally check if a field is required
  if (field?.rules) {
    const fieldRules = field?.rules;

    if (fieldRules) {
      const requiredRule = fieldRules.find((r) => r.propertyName === "required");
      if (requiredRule) {
        let isRequired = false;

        if (isChildField) {
          isRequired = evaluateRule(requiredRule, parentState);
        } else {
          isRequired = evaluateRule(requiredRule, state);
        }

        field.required = isRequired;
      }
    }
  }
  // Conditionally render a field
  if (!field.drawerField && !checkRules(field, state, isChildField, parentState)) {
    return null;
  }

  const testId = generateTestIdForDynamicField({
    fieldName: field.name || "",
    fieldType: field.objectFieldType || "",
    objectName: parentObjectName || "",
  });
  if ((field.name === "discontinuedBy" || field.name === "cancelledBy") && !field.drawerField) {
    state[field.name] = { label: user.name, value: user.userKey };
  }
  switch (field.objectFieldType.toLowerCase()) {
    case "text": {
      if (field.richText) {
        return (
          <FormControl key={field.name}>
            {showLabel ? (
              <FieldLabel
                label={field.label}
                labelFor={field.name}
                helperText={field.helperText}
                isRequired={field.required}
              />
            ) : null}
            <TextEditor
              id={field.name}
              name={field.name}
              className={checkWordMatch(`${field.name}`, errors) ? "error-outline" : ""}
              value={state[field.name] || ""}
              onChange={(value) => onChange(field.name, value)}
              disabled={isFieldDisabled}
              testId={testId}
              autoFocus={autoFocusState}
              {...prepareValidationRulesAndMessages(field)}
            />
          </FormControl>
        );
      }

      if (field.multiLine) {
        return (
          <FormControl key={field.name}>
            {showLabel ? (
              <FieldLabel
                label={field.label}
                labelFor={field.name}
                helperText={field.helperText}
                isRequired={field.required}
              />
            ) : null}
            <TextArea
              id={field.name}
              name={field.name}
              value={state[field.name] || ""}
              onChange={(value) => onChange(field.name, value)}
              disabled={isFieldDisabled}
              testId={testId}
              autoFocus={autoFocusState}
              {...prepareValidationRulesAndMessages(field)}
            />
          </FormControl>
        );
      }

      return (
        <InputField
          key={field.name}
          field={field}
          handleDispatch={onChange}
          name={field.name}
          state={state}
          className={checkWordMatch(`${field.name}`, errors) ? "error-outline" : ""}
          objectName={objectData?.objectName}
          value={state[field.name] || ""}
          onChange={(value) => onChange(field.name, value)}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          testId={testId}
          autoFocus={autoFocusState}
          {...prepareValidationRulesAndMessages(field)}
        />
      );
    }

    case "calculated": {
      return (
        <InputField
          key={field.name}
          field={field}
          className={checkWordMatch(`${field.name}`, errors) ? "error-outline" : ""}
          name={field.name}
          value={state[field.name] || ""}
          onChange={(value) => onChange(field.name, value)}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          testId={testId}
          autoFocus={autoFocusState}
          {...prepareValidationRulesAndMessages(field)}
        />
      );
    }

    case "number":
    case "money":
    case "url":
    case "email":
    case "area":
    case "percentage":
      return (
        <InputField
          key={field.name}
          field={field}
          className={checkWordMatch(`${field.name}`, errors) ? "error-outline" : ""}
          name={field.name}
          value={state[field.name]?.toString() || ""}
          onChange={(value) => onChange(field.name, value)}
          isEmailUnique={state?.isEmailUnique}
          handleDispatch={onChange}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          objectName={objectData?.objectName}
          testId={testId}
          autoFocus={autoFocusState}
          checkValidation={!field.drawerField}
          {...prepareValidationRulesAndMessages(field)}
        />
      );

    case "autonumber":
      field.disabled = true;
      if (field?.drawerField) {
        field.disabled = false;
      }

      return (
        <InputField
          key={field.name}
          field={field}
          name={field.name}
          value={state[field.name] || ""}
          onChange={(value) => onChange(field.name, value)}
          showLabel={showLabel}
          testId={testId}
          autoFocus={autoFocusState}
          checkValidation={!field.drawerField}
          {...prepareValidationRulesAndMessages(field)}
        />
      );

    case "checkbox":
      return (
        <Checkbox
          key={field.name}
          id={field.name}
          label={showLabel ? field.label : ""}
          value={state[field.name]}
          onChange={() => onChange(field.name, !state[field.name])}
          disabled={isFieldDisabled}
          testId={testId}
          autoFocus={autoFocusState}
          {...prepareValidationRulesAndMessages(field)}
        />
      );

    case "geolocation":
      return (
        <GeolocationField
          key={field.name}
          name={field.name}
          value={state[field.name]}
          onChange={(key, value) => onChange(key, value)}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          testId={testId}
          autoFocus={autoFocusState}
          {...prepareValidationRulesAndMessages(field)}
        />
      );

    case "phone":
      return (
        <FormControl key={field.name}>
          {showLabel ? (
            <FieldLabel
              label={field.label}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null}
          <PhoneNumberSelector
            id={field.name}
            name={field.name}
            value={state[field.name]}
            onChange={(value) => onChange(field.name, value)}
            disabled={isFieldDisabled}
            objectName={objectData?.objectName}
            testId={testId}
            isPhoneUnique={state?.isPhoneUnique}
            handleDispatch={onChange}
            autoFocus={autoFocusState}
            required={field.required}
            checkValidation={!field.drawerField}
            {...prepareValidationRulesAndMessages(field)}
          />
        </FormControl>
      );

    case "select": {
      const additionalProps = {};

      if (isChildField && !isInsideModal) {
        additionalProps.menuPortalTarget = document.body;
      }

      if (field.showAsRadio || field.name === "durationType") {
        return (
          <div className="row">
            {field.options.map((option, index) => (
              <div className="col-md-6" key={`${field.name}-radio-${index}`}>
                <RadioWithIcon
                  label={option.label}
                  optionValue={option.value}
                  value={state[field.name]?.value}
                  onChange={(value) => onChange(field.name, { value })}
                  disabled={isFieldDisabled}
                  testId={testId}
                  autoFocus={autoFocusState}
                />
              </div>
            ))}
          </div>
        );
      }
      return (
        <SelectField
          key={`${field.name}${autoFocusState}`}
          field={field}
          value={state[field.name]}
          onChange={(value) => onChange(field.name, value)}
          name={field.name}
          className={checkWordMatch(`${field.name}`, errors) ? "error-outline" : ""}
          isEditing={isEditing}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          isChildField={isChildField}
          relationalFieldState={relationalFieldState}
          formState={state}
          testId={testId}
          {...additionalProps}
          autoFocus={autoFocusState}
          {...prepareValidationRulesAndMessages(field)}
        />
      );
    }

    case "time":
      return (
        <FormControl key={field.name}>
          {showLabel ? (
            <FieldLabel
              label={field.label}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null}
          <CustomizedTimePicker
            id={field.name}
            name={field.name}
            value={state[field.name]}
            onChange={(value) => onChange(field.name, value)}
            minTime={field.minTime}
            maxTime={field.maxTime}
            required={field.required}
            disabled={isFieldDisabled}
            testId={testId}
            autoFocus={autoFocusState}
          />
        </FormControl>
      );

    case "date": {
      const additionalProps = {};
      if (isChildField && parentFieldName && !isInsideModal) {
        additionalProps.portalContainer = document.getElementById(`${parentFieldName}-container`);
      }

      return (
        <FormControl key={field.name}>
          {showLabel ? (
            <FieldLabel
              label={field.label}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null}
          {
            !field.dynamicFilterDrawerField && (
              <CustomizedDatePicker
                id={field.name}
                name={field.name}
                value={state[field.name]}
                className={
                  checkWordMatch(`${field.name}`, errors) ?
                    "error-outline-date-width error-outline" :
                    ""
                }
                onChange={(value) => onChange(field.name, value)}
                format={getDateFormat()}
                minDate={
                  field.minDate || checkDate(field, state, onChange, false, objectData?.objectName)
                }
                maxDate={
                  field.maxDate || checkDate(field, state, onChange, true, objectData?.objectName)
                }
                required={field.required}
                disabled={isFieldDisabled}
                testId={testId}
                autoFocus={autoFocusState}
                {...additionalProps}
              />
            )
          }
          {field.dynamicFilterDrawerField && (
            <CustomizedDateRangePicker
              id={field.name}
              name={field.name}
              value={state[field.name]}
              onChange={(value) => onChange(field.name, value)}
            />
          )}
        </FormControl>

      );
    }

    case "datetime":
      return (
        <FormControl key={field.name}>
          {showLabel ? (
            <FieldLabel
              label={field.label}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null}
          <CustomizedDateTimePicker
            id={field.name}
            name={field.name}
            value={state[field.name]}
            onChange={(value) => onChange(field.name, value)}
            required={field.required}
            testId={testId}
            disabled={isFieldDisabled}
            autoFocus={autoFocusState}
          />
        </FormControl>
      );

    case "lookup": {
      const additionalProps = {};

      if (isChildField && !isInsideModal) {
        additionalProps.menuPortalTarget = document.body;
      }

      if (isChildField) {
        additionalProps.parentState = parentState;
      }

      if (field.lookupObjectName === "LedgerAccount") {
        additionalProps.formatOption = formatGLAccountOption;

        if (
          parentObjectName === "ClearCheque_PaymentDetail" ||
          parentObjectName === "ClearCheque" ||
          parentObjectName === "BounceCheque_PaymentDetail" ||
          parentObjectName === "BounceCheque" ||
          parentObjectName === "Payment" ||
          parentObjectName === "PDC"
        ) {
          additionalProps.filterOption = filterClearChequePaymentAccountOption;
        }
      }

      if (field.lookupObjectName === "Bank") {
        additionalProps.formatOption = formatBankOption;
      }

      if (field.lookupObjectName === "TaxRule") {
        additionalProps.filterOption = filterTaxRuleOption;
      }

      if (
        parentObjectName === "SupervisorPettyCashRequest" &&
        field.lookupObjectName === "Employee" &&
        field.name === "supervisor"
      ) {
        additionalProps.filterOption = filterSupervisorPettyCashRequestEmployee;
      }

      let lookupFieldName = field.name;

      if (field.isCompanyAccount && company) {
        const { label, value } = company;
        const pascalCaseFieldName = pascalize(field.name);
        const camelCaseCompanyName = camelize(label);
        lookupFieldName = `${camelCaseCompanyName}${pascalCaseFieldName}`;
        additionalProps.apiFilters = {
          company: value,
        };
      }

      const handleChange = (fieldName, value) => {
        if (field.isCompanyAccount && value && company) {
          value.companyId = company.value;
          value.companyName = camelize(company.label);
          value.recordId = parentId;
          value.isCompanyAccount = true;
        }
        onChange(fieldName, value);
      };

      return (
        <LookupField
          key={lookupFieldName}
          field={field}
          value={state[lookupFieldName]}
          className={checkWordMatch(`${lookupFieldName}`, errors) ? "error-outline" : ""}
          onChange={(value) => handleChange(lookupFieldName, value)}
          showCreateNew={!isChildField}
          showLabel={showLabel}
          isChildField={isChildField}
          relationalFieldState={relationalFieldState}
          disabled={isFieldDisabled}
          parentId={parentId}
          isEditing={isEditing}
          formState={state}
          testId={testId}
          autoFocus={autoFocusState}
          handleDispatch={onChange}
          {...additionalProps}
          {...prepareValidationRulesAndMessages(field)}
        />
      );
    }

    case "polymorphic": {
      const additionalProps = {};

      if (isChildField && !isInsideModal) {
        additionalProps.menuPortalTarget = document.body;
      }

      return (
        <PolymorphicField
          key={field.name}
          field={field}
          value={state[field.name]}
          onChange={(value) => onChange(field.name, value)}
          showLabel={showLabel}
          disabled={isFieldDisabled}
          isChildField={isChildField}
          relationalFieldState={relationalFieldState}
          parentId={parentId}
          isEditing={isEditing}
          formState={state}
          testId={testId}
          autoFocus={autoFocusState}
          {...additionalProps}
          {...prepareValidationRulesAndMessages(field)}
        />
      );
    }

    case "attachment": {
      const handleFileUpload = (files, setProgress) =>
        uploadAttachmentWithDynamicObjectRecord(
          {
            objectName: parentObjectName,
            attachmentFieldName: field.name,
            files,
            recordId: childParentId ?? parentId,
          },
          setProgress
        );

      return (
        <>
          {showLabel ? (
            <FieldLabel
              label={field.label}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null}
          <FileUploader
            key={field.name}
            files={state[field.name] || []}
            setFiles={(value) => onChange(field.name, value)}
            isEditing={isEditing}
            allowMultiple={field.allowMultiple}
            disabled={isFieldDisabled}
            testId={testId}
            autoFocus={autoFocusState}
            required={field.required}
            handleFileUpload={handleFileUpload}
          />
        </>
      );
    }

    case "table":
      return (
        <RelationalField
          key={field.name}
          value={state[field.name] ?? []}
          parentState={state}
          onChange={onChange}
          field={field}
          parentId={parentId}
          disabled={isFieldDisabled}
          fieldLayout={fieldLayout}
          onChildStateChange={onChildStateChange}
          onChildFieldBlur={onChildFieldBlur}
          showFormOnly={isInsideModal}
          objectData={objectData}
          testId={testId}
          showFormInModal={field.showInModel}
          autoFocus={autoFocusState}
          updateTableDataOnSubmit={updateTableDataOnSubmit}
        />
      );

    default:
      return (
        <div key={field.name}>
          {field.objectFieldType}
          {field.name}
        </div>
      );
  }
};

export const renderStatus = (value, colorMap) => (
  <Status baseClassName="status-cell" status={value} bgColor={colorMap.get(value?.toLowerCase())}>
    <StatusCircle color={colorMap.get(value?.toLowerCase())} />
  </Status>
);

function convertTimeTo12HrFormat(time24) {
  const [hours, minutes] = time24.split(":");
  let period = "AM";
  let hours12 = parseInt(hours, 10);

  if (hours12 >= 12) {
    period = "PM";
    if (hours12 > 12) {
      hours12 -= 12;
    }
  }

  if (hours12 === 0) {
    hours12 = 12;
  }

  return `${hours12}:${minutes} ${period}`;
}

export const renderDynamicFieldValue = (item) => {
  const { fieldType, fieldName, value } = item;
  switch (fieldType.toLowerCase()) {
    case "lookup":
    case "polymorphic": {
      const {
        id, isStaticObject, allowMultiple, values
      } = item;

      if (isStaticObject) {
        return <Link to={handleDrawer(id, null, false, "staticObjectDrawers")}>{value}</Link>;
      }

      if (allowMultiple) {
        return (
          <>
            {values.map((v, index) => (
              <React.Fragment key={`v-${index}`}>
                <Link to={handleDrawer(v.value, null, false, "dynamicObjectDrawers")}>
                  {v.label}
                </Link>
                {index < values.length - 1 && <span>, </span>}
              </React.Fragment>
            ))}
          </>
        );
      }

      return <Link to={handleDrawer(id, null, false, "dynamicObjectDrawers")}>{value}</Link>;
    }

    case "select":
      if (statusFieldNames.includes(fieldName)) {
        return renderStatus(item.value, statusColorMap);
      }

      return value;

    case "time":
      return convertTimeTo12HrFormat(value);

    case "qrcode":
      return <img src={`data:image/png;base64,${value}`} alt="qrcode" />;

    default:
      return value;
  }
};

export const checkDynamicObjectPermission = ({ permissions, scope, action }) => {
  if (!permissions) return false;

  return permissions.Model[scope]?.includes("Any") || permissions.Model[scope]?.includes(action);
};

export const validateFields = (fields, payload, parentFieldLabel = null) => {
  const formErrors = fields.map((field) => {
    let error = "";
    const {
      name,
      label,
      objectFieldType: type,
      required,
      minimum,
      maximum,
      minimumLength,
      maximumLength,
    } = field;
    const value = payload[name];

    let fieldLabel = label;
    if (parentFieldLabel) {
      fieldLabel += ` field of ${parentFieldLabel}`;
    }

    if (!required && !value) {
      return error;
    }

    if (required) {
      if (["number", "money", "percentage", "area"].includes(type.toLowerCase())) {
        if (value === 0) return error;
      }

      if (type.toLowerCase() === "table") {
        if (!value || !value?.length) {
          error = `${fieldLabel} is required`;
          return error;
        }
      }

      if (!value) {
        error = `${fieldLabel} is required`;
        return error;
      }
    }

    switch (type.toLowerCase()) {
      case "number":
      case "money":
      case "area":
        if (minimum && Number(value) < minimum) {
          error = `${fieldLabel} must be equal to or greater than ${field.minimum}`;
        }
        if (maximum && Number(value) > maximum) {
          error = `${fieldLabel} must be equal to or less than ${field.maximum}`;
        }
        break;

      case "text":
        if (minimumLength && `${value}`.length < minimumLength) {
          error = `${fieldLabel} must be equal to or greater than ${field.minimumLength} characters`;
        }
        if (maximumLength && `${value}`.length > maximumLength) {
          error = `${fieldLabel} must be equal to or less than ${field.maximumLength} characters`;
        }
        break;

      default:
        break;
    }

    return error;
  });

  return (
    formErrors
      // Filter error messages that have length
      .filter((e) => e)
      // Filter all unique error messages
      .filter((value, index, array) => array.indexOf(value) === index)
  );
};

export const extractStringFromBracketsAndDots = (str) => {
  const bracketRegex = /\{(.+?)\}/;
  const dotRegex = /\./;

  const bracketMatch = str.match(bracketRegex);
  const dotMatch = str.match(dotRegex);

  if (bracketMatch) {
    const extractedContent = bracketMatch[1];

    if (dotMatch) {
      // If dots are found, split the string around the first dot
      const [left, right] = extractedContent.split(".");
      return { left, right };
    }
    // If no dots are found, return the extracted content
    return extractedContent;
  }

  if (dotMatch) {
    const [left, right] = str.split(".");
    return { left, right };
  }

  // If no brackets are found, return the original string
  return str;
};

export const createNewObjURL = ({
  createObjectName,
  createObjectFieldName,
  createObjectDisplayFieldName,
  createObjectAllowMultiple,
}) => {
  const { pathname } = window.location;

  const searchParams = qs.stringify({
    prevForm: pathname,
    createObjectFieldName,
    createObjectDisplayFieldName,
    createObjectAllowMultiple,
  });

  return `/admin/${kebabCase(createObjectName)}/new?${searchParams}`;
};

export const parseSelectFieldValue = (value) => {
  let selectOption;

  try {
    selectOption = camelizeKeys(JSON.parse(value));
  } catch (e) {
    selectOption = camelizeKeys(value);
  }

  return selectOption;
};

export function transformObject(obj) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
      if (Object.keys(obj[key]).length === 1 && "key" in obj[key]) {
        obj[key] = obj[key].key;
      } else {
        transformObject(obj[key]);
      }
    }
  }
}
