import { getAgingArchiveColumns, getAgingArchiveRows } from "./aging-archive";
import { getAgingArchive } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportAgingArchive({ activeCompany }) {
  return (
    <DynamicReport
      reportName="rent-roll"
      activeCompany={activeCompany}
      columnsDataProvider={getAgingArchiveColumns}
      rowsDataProvider={getAgingArchiveRows}
      fetchData={getAgingArchive}
      exportUrl="reports/export-query-report/tenant-aging-archive"
      filtersInitialState={{}}
      orderBy="TenantNumber"
      showDateRangeFilter={false}
      showDateFilter
    />
  );
}
