import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getRevenueRecognitionDetailColumns() {

  const colArr = [
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("text"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("text"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Cont. Num",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("text"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Number",
      value: true,
      columnId: "customerNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("text"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Start Date",
      value: true,
      columnId: "startDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "End Date",
      value: true,
      columnId: "endDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Break Date",
      value: true,
      columnId: "breakDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => value
    },
    {
      label: "Cont. Amount",
      value: true,
      columnId: "contractAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Recog. Amount",
      value: true,
      columnId: "recognizedAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Recognised Till",
      value: true,
      columnId: "recognizedTill",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Recog. Up To Date",
      value: true,
      columnId: "recognizedUpToDate",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Unrealized Revenue",
      value: true,
      columnId: "unrealizedReveneue",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
