import { getFarRows, getFarColumns } from "./far-statement";
import { getFARReport } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const farFilters = {
  AssetKey: "*",
};

const farFilterOptions = [
  { key: "assetKey", apiKey: "AssetKey" },
];

export default function ReportFAR({ activeCompany }) {
  return (
    <DynamicReport
      reportName="fixed-assets-register"
      activeCompany={activeCompany}
      columnsDataProvider={getFarColumns}
      rowsDataProvider={getFarRows}
      fetchData={getFARReport}
      exportUrl="reports/export-query-report/fixed-assets-register"
      filtersInitialState={farFilters}
      filterOptions={farFilterOptions}
      orderBy="Building"
      showDateRangeFilter={false}
    />
  );
}
