import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getSupplierAdvancePaymentColumns() {

  const colArr = [
    {
      label: "Supplier Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("supplierName"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Advance Payment Number",
      value: true,
      columnId: "advancePaymentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Payment Date",
      value: true,
      columnId: "paymentDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : ""),
    },
    {
      label: "Adjustment Amount",
      value: true,
      columnId: "adjustmentAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Taken Amount",
      value: true,
      columnId: "takenAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Running Balance",
      value: true,
      columnId: "runningBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Remaining Amount",
      value: true,
      columnId: "remainingAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Remarks",
      value: true,
      columnId: "remarks",
      width: estimatedColumnWidth("remarks"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Expense Number",
      value: true,
      columnId: "expenseNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Expense Date",
      value: true,
      columnId: "expenseDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : ""),
    },
    {
      label: "Status",
      value: true,
      columnId: "status",
      width: estimatedColumnWidth("status"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Exp Amount",
      value: true,
      columnId: "expenseAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Exp Portion",
      value: true,
      columnId: "expensePortion",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(value || ""),
    }
  ];

  return colArr;
}
