import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input
} from "@hydra/atom/components";
import { CustomDropdown } from "@/components/common";
import { getFixedAssetListingRows, getFixedAssetListingColumns } from "./fixed-asset-listing-statement";
import { inflows as emptyInflows, outflows as emptyOutflows } from "./profit-and-loss-statement/data";
import { calculateOutputVariables } from "./helpers";

const applyChange = (change) => (groups) => groups.map((group) => (group.title === change.rowId ?
  {
    ...group,
    // eslint-disable-next-line max-len
    values: group.values.map((value, idx) => (change.columnId === idx + 1 ? change.newCell.value : value)
    )
  } :
  group)
);

function ReportFixedAssetListing() {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [openingBalance, setOpeningBalance] = useState(10000);
  const [cashInflow, setCashInflow] = useState(() => [...emptyInflows]);
  const [columnData, setColumnData] = useState([]);
  const [cashOutflow, setCashOutflow] = useState(() => [
    ...emptyOutflows
  ]);
  const [filterText, setFilterText] = useState("");
  const data = [
    {
      label: "All",
      value: true
    },
    {
      label: "Transaction",
      value: true
    },
    {
      label: "Date",
      value: true
    },
    {
      label: "Num",
      value: true
    },
    {
      label: "Name",
      value: true
    },
    {
      label: "Building",
      value: true
    },
    {
      label: "Unit",
      value: true
    },
    {
      label: "Account",
      value: true
    }, {
      label: "Debit",
      value: true
    },
    {
      label: "Credit",
      value: true
    }
  ];
  const columns = useMemo(
    () => getFixedAssetListingColumns(ref?.current?.clientWidth, columnData),
    [ref?.current?.clientWidth, columnData]
  );

  const rowsData = [];
  const inputVariables = {
    cashInflow,
    cashOutflow,
    openingBalance,
    rowsData
  };
  const years = new Array(7).fill(0);
  const outputVariables = calculateOutputVariables(inputVariables, years);
  const plannerData = {
    ...inputVariables,
    ...outputVariables,
    rowsData
  };

  const rows = useMemo(() => (getFixedAssetListingRows(plannerData, columnData)), [rowsData]);

  const handleChanges = (changes) => {
    changes.forEach((change) => {
      setCashInflow((cash) => applyChange(change)(cash));
      setCashOutflow((cash) => applyChange(change)(cash));
    });
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <CustomDropdown data={data} setColumnData={setColumnData} />
        </div>
      </div>
      <div className="react-grid-container">
        <ReactGrid
          rows={rows}
          columns={columns}
          onCellsChanged={handleChanges}
          enableFillHandle
          enableRangeSelection
        />
      </div>
    </div>
  );
}

export default ReportFixedAssetListing;
