import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination, FormControl
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { subYears } from "date-fns";
import { formatApiPayloadDate } from "@/utils/helpers";
import { useDebounce } from "@/hooks";
import {
  SvgIcon, CustomDropdown, IconButton, CustomizedDateRangePicker, CustomizedDatePicker
} from "@/components/common";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";
import { mergeDataByMonth } from "@/components/finance/reporting/helpers";
import { ReportCard } from "@/components/property";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportCardContainer({ reportCardData }) {
  const { size, data, dataChunks } = reportCardData;

  const chunkedData = [];
  for (let i = 0; i < data.length; i += dataChunks) {
    chunkedData.push(data.slice(i, i + dataChunks));
  }

  return (
    <div className="cards-container">
      {chunkedData.slice(0, size).map((chunk, index) => (
        <div className="card-container" key={index}>
          <ReportCard data={chunk} />
        </div>
      ))}
    </div>
  );
}

function DynamicReport({
  reportName,
  activeCompany,
  columnsDataProvider,
  rowsDataProvider,
  fetchData,
  exportUrl,
  filtersInitialState,
  filterOptions,
  orderBy,
  showDateRangeFilter = true,
  isPaginated = true,
  showDateFilter = false,
  reportCardData,
  onDataLoaded
}) {
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(columnsDataProvider);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(filtersInitialState);
  const debouncedFilter = useDebounce(filters, 600);
  const [dateRange, setDateRange] = useState([subYears(new Date(), 1), new Date()]);
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  console.log("filterOptions", filterOptions);
  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const formatFilters = (data) => {
    let filterData = filtersInitialState;
    if (!data) {
      return {};
    }
    if (showDateRangeFilter) {
      filterData = {
        StartDate: formatApiPayloadDate(dateRange[0]),
        EndDate: formatApiPayloadDate(dateRange[1]),
        ...filtersInitialState
      };
    }

    if (showDateFilter) {
      filterData.Date = formatApiPayloadDate(date);
    }

    filterOptions?.forEach((option) => {
      if (data[option.key]) {
        if (option?.keyType === "label") {
          filterData[option.apiKey] = data[option.key].label;
        } else if (option?.keyType === "string") {
          filterData[option.apiKey] = data[option.key];
        } else if (option?.keyType === "number") {
          filterData[option.apiKey] = data[option.key].number;
        } else if (option?.keyType === "date") {
          filterData[option.apiKey] = formatApiPayloadDate(data[option.key]);
        } else {
          filterData[option.apiKey] = data[option.key].id || data[option.key].value;
        }
      }
    });
    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/${exportUrl}?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(debouncedFilter))}`, "_blank", "noopener,noreferrer");

  const { data: reportData, isLoading } = useQuery([reportName, currentPage,
    perPage.value, debouncedFilter, dateRange, date], () =>
    fetchData(isPaginated ? {
      pageNumber: currentPage,
      pageSize: perPage.value,
      orderBy,
      ...formatFilters(debouncedFilter),
    } : {
      orderBy,
      ...formatFilters(debouncedFilter),
    })
  );

  const totalCount = useMemo(() => reportData?.totalCount || 0, [reportData]);

  useEffect(() => {
    if (reportData?.data && onDataLoaded) {
      onDataLoaded(reportData?.data);
    }
  }, [reportData]);

  const formatReport = (transaction) => {
    let formattedData = transaction;

    if (reportName === "building-revenue" && formattedData?.length > 0) {
      formattedData = mergeDataByMonth(transaction);
    }

    if (filterText) {
      return formattedData?.filter((obj) =>
        Object.values(obj).some((value) =>
          value?.toString().toLowerCase().includes(filterText.toLowerCase())
        )
      );
    }
    return formattedData;
  };

  const onClose = (value) => {
    setIsOpen(value);
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      rowsDataProvider({
        data: {
          data: formatReport(reportData?.data) || [],
        },
        columns,
      }),
    [reportData, columns, filterText]
  );

  return (
    <>
      {reportCardData && <ReportCardContainer reportCardData={reportCardData} />}
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md">
            <Input
              className="input-height-fix"
              value={filterText}
              onChange={setFilterText}
              placeholder="Search by name"
            />
          </div>
          {showDateRangeFilter && (
          <div className="col-md general-date-range">
            <FormControl>
              <CustomizedDateRangePicker
                name="dateRange"
                value={dateRange}
                onChange={(value) => setDateRange(value)}
                required
              />
            </FormControl>
          </div>
          )}
          {showDateFilter && (
          <div className="col-md general-date-range">
            <FormControl>
              <CustomizedDatePicker
                name="date"
                value={date}
                onChange={(value) => setDate(value)}
              />
            </FormControl>
          </div>
          )}
          <div className="col-md btn-icon-container">
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel()}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
            <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
            {filterOptions && (
            <>
              <Button
                onClick={() => setIsOpen(true)}
                small
                bordered
              >
                <Button.Prepend>
                  <SvgIcon icon="filter-lines" />
                </Button.Prepend>
              </Button>
              <ReportFilterDrawer
                filters={filters}
                setFilters={setFilters}
                type={reportName}
                size={400}
                isOpen={isOpen}
                onClose={onClose}
              />
            </>
            )}
          </div>
        </div>
        <div className="react-grid-container">
          {!isLoading ? (
            <ReactGrid
              rows={rows}
              columns={columns}
              enableFillHandle
              enableRangeSelection
              onColumnResized={handleColumnResize}
              disableVirtualScrolling
            />
          ) : (
            <TableSkeleton />
          )}
          {!isLoading && isPaginated && (
          <Pagination
            className="dashboard-pagination"
            options={perPageOptions}
            perPage={perPage}
            onSelectChange={(val) => handlePagination(currentPage, val)}
            pageRangeDisplayed={3}
            pageNo={currentPage}
            handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
            showResults
            offset={0}
            totalItems={totalCount}
            pageCount={Math.ceil(totalCount / perPage.value)}
            reactPaginateProps={{
              previousLabel: <span className="material-icons">&#xe5cb;</span>,
              nextLabel: <span className="material-icons">&#xe5cc;</span>,
              forcePage: currentPage - 1,
            }}
          />
          )}
        </div>
      </div>
    </>
  );
}

export default DynamicReport;
