import { useParams } from "react-router-dom";

import {
  useState, useEffect
} from "react";
import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "@/utils/helpers";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { BoxedContent, Header, HeaderLeftContent } from "@/components/common";
import {
  ReportSheetBalanceSheet, ReportSheetCashFlow, ReportSheetProfitLoss,
  ReportJournalLedger,
  ReportGeneralJournal,
  ReportSupplierPayment,
  ReportTrialBalance,
  ReportSheetProfitLossVsActual,
  ReportSheetAssetValuation,
  ReportFixedAssetListing,
  ReportReturnedCheque,
  ReportRentRoll,
  ReportPlannedVsActualPpmInhouse,
  ReportForthComingRenewal,
  ReportSheetInventorySummary,
  ReportAgentSummary,
  ReportTransactionDetail,
  ReportTaxLiability,
  ReportVATDetail,
  ReportVATException,
  ReportAging,
  ReportVacancyAging,
  ReportForthcomingVacancy,
  ReportUnitReady,
  ReportUtilityContract,
  ReportOccupancy,
  ReportOpex,
  ReportInputVAT,
  ReportOutputVAT,
  ReportApprotionment,
  ReportFAR,
  ReportBounceCheque,
  ReportExpenseDetails,
  ReportClearCheque,
  ReportCollectCheque,
  ReportSupplierAging,
  ReportCollection,
  ReportCollectChequeArchive,
  ReportPlannedVsActualPpmExternal,
  ReportPettyCashByBuilding,
  ReportDailyTransactionDetail,
  ReportBuildingCost,
  ReportBlanketAgreementDetail,
  ReportBuildingRevenue,
  ReportSupplierAdvancePayment,
  ReportInvoiceListing,
  ReportTenantOutstanding,
  ReportTenantOutstandingArchive,
  ReportAgingArchive,
  ReportWorkOrderAging,
  ReportVATSummary,
  ReportStandardRatedSales,
  ReportStandardRatedPurchase,
  ReportOutOfScope,
  ReportZeroRatedPurchase,
  ReportZeroRatedSales,
  ReportExemptSuppliesSale,
  ReportExemptSuppliesPurchase,
  ReportRentRollArchive,
  ReportVATReconciliation,
  ReportRevenueRecognitionDetail
} from "@/components/finance/reporting";
import { selectActiveCompany } from "@/store/appSlice";
import { getAccountingPeriods } from "@/api/finance/accountingPeriodApi";
import appSettings from "@/settings";
import { selectPermissions } from "@/store/userSlice";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { ReportCard } from "@/components/property";
import { checkIfValueExists } from "@/utils/report/helpers";
import { ReportContractSecurityDeposit } from "../../../components/finance/reporting";
import ReportSupplierAgingArchive from "../../../components/finance/reporting/ReportSupplierAgingArchive";

const getReportHeader = (reportType) => {
  switch (reportType) {
    case "balance-sheet":
      return { title: "Balance Sheet", subTitle: "" };

    case "profit-and-loss":
      return { title: "Profit/Loss Statement", subTitle: "" };

    case "cash-flow-statement":
      return { title: "Cash Flow Statement", subTitle: "" };

    case "journal-ledger":
      return { title: "Journal Ledger", subTitle: "" };

    case "general-journal":
      return { title: "Emirates Property Investment Company", subTitle: "General Journal" };

    case "trial-balance":
      return { title: "Trial Balance", subTitle: "" };

    case "tax-liability":
      return { title: "Tax Liability", subTitle: "" };

    case "profit-and-loss-vs-actual":
      return { title: "Profit and Loss Budget Vs Actual", subTitle: "" };

    case "asset-valuation":
      return { title: "Asset Valuation", subTitle: "" };

    case "fixed-asset-listing":
      return { title: "Fixed Asset Listing", subTitle: "" };

    case "returned-cheques":
      return { title: "Returned Cheques", subTitle: "" };

    case "rent-roll":
      return { title: "Rent Roll", subTitle: "" };

    case "rent-roll-archive":
      return { title: "Rent Roll Archive", subTitle: "" };

    case "fixed-assets-register":
      return { title: "FAR Report", subTitle: "" };

    case "opex-report":
      return { title: "Opex Report", subTitle: "" };

    case "occupancy":
      return { title: "Occupancy Report", subTitle: "" };

    case "vat-input":
      return { title: "Input VAT Report", subTitle: "" };

    case "approtionment-report":
      return { title: "Approtionment Report", subTitle: "" };

    case "vat-output":
      return { title: "Output VAT Report", subTitle: "" };

    case "forthcoming-renewals":
      return { title: "Forth Coming Renewals", subTitle: "" };

    case "tenant-statement":
      return { title: "Tenant Statement List", subTitle: "" };

    case "bounce-cheque":
      return { title: "Bounce Cheque Statement", subTitle: "" };

    case "clear-cheque":
      return { title: "Clear Cheque Statement", subTitle: "" };

    case "collect-cheque":
      return { title: "To be Clear Cheque Statement", subTitle: "" };

    case "agent-summary":
      return { title: "Lease By Employee / Agent Summary", subTitle: "" };

    case "transaction-detail":
      return { title: "Transaction Detail", subTitle: "" };

    case "vat-details":
      return { title: "VAT Detail", subTitle: "" };

    case "vat-summary":
      return { title: "VAT Summary Report", subTitle: "" };

    case "vat-exceptions":
      return { title: "VAT Exception", subTitle: "" };

    case "customer-aging":
      return { title: "Tenant Aging", subTitle: "" };

    case "vacancy-aging":
      return { title: "Vacancy Aging", subTitle: "" };

    case "forthcoming-vacancy":
      return { title: "Forthcoming Vacancy", subTitle: "" };

    case "unit-ready-report":
      return { title: "Unit Ready Report", subTitle: "" };

    case "vendor-aging":
      return { title: "Supplier Aging", subTitle: "" };

    case "supplier-aging-archive":
      return { title: "Supplier Aging Archive", subTitle: "" };

    case "supplier-aging-report":
      return { title: "Supplier Statement", subTitle: "" };

    case "supplier-payment":
      return { title: "Supplier Payments", subTitle: "" };

    case "supplier-paid-invoices":
      return { title: "Supplier Paid Invoices", subTitle: "" };

    case "unit-availability":
      return { title: "Unit Availability", subTitle: "" };

    case "incident-report":
      return { title: "Incident Report", subTitle: "" };

    case "fitout-report":
      return { title: "Fit Out Report", subTitle: "" };

    case "amc-report":
      return { title: "AMC Contract Status Report", subTitle: "" };

    case "facility-contract-report":
      return { title: "Facility Contract Report", subTitle: "" };

    case "utility-contract":
      return { title: "Utility Contract Report", subTitle: "" };

    case "collection-report":
      return { title: "Collection Report", subTitle: "" };

    case "collection-archive-report":
      return { title: "Collection Report", subTitle: "" };

    case "bank-account-statement-report":
      return { title: "Bank Account Statement Report", subTitle: "" };

    case "revenue-recognition-report":
      return { title: "Revenue Recognition", subTitle: "" };

    case "work-order-report":
      return { title: "Work Order Report", subTitle: "" };

    case "work-order-aging":
      return { title: "Work Order Aging Report", subTitle: "" };

    case "ppm-report-internal":
      return { title: "Planned Vs Actual PPM Report", subTitle: "" };

    case "ppm-report-external":
      return { title: "Planned Vs Actual PPM Report", subTitle: "" };

    case "tenant-report":
      return { title: "Tenant Report", subTitle: "" };

    case "petty-cash-claim-by-building":
      return { title: "Petty Cash By Building", subTitle: "" };

    case "petty-cash-by-supervisor-report":
      return { title: "Petty Cash By Supervisor", subTitle: "" };

    case "petty-cash-claim-categories":
      return { title: "Petty Cash Claim", subTitle: "" };

    case "petty-cash-transaction-report":
      return { title: "Petty Cash Transaction Report", subTitle: "" };

    case "daily-transaction-detail":
      return { title: "Daily Transaction Detail Report", subTitle: "" };

    case "supplier-report":
      return { title: "Supplier Report", subTitle: "" };

    case "revenue-recognition-details":
      return { title: "Revenue Recognition Details", subTitle: "" };

    case "expense-report":
      return { title: "Expense Report", subTitle: "" };

    case "legal-case-report":
      return { title: "Legal Case Report", subTitle: "" };

    case "building-cost-report":
      return { title: "Building Cost Report", subTitle: "" };

    case "building-revenue-report":
      return { title: "Building Revenue Report", subTitle: "" };

    case "blanket-agreement-detail-report":
      return { title: "Blanket Agreement Detail Report", subTitle: "" };

    case "supplier-advance-payments":
      return { title: "Suppliers Advance Payment Report", subTitle: "" };

    case "invoice-listing-report":
      return { title: "Invoice Listing Report", subTitle: "" };

    case "expense-detail-report":
      return { title: "Expense Detail Report", subTitle: "" };

    case "tenant-outstanding":
      return { title: "Tenant Outstanding Report", subTitle: "" };

    case "tenant-outstanding-archive":
      return { title: "Tenant Outstanding Archive Report", subTitle: "" };

    case "tenant-aging-archive":
      return { title: "Tenant Aging Archive Report", subTitle: "" };

    case "standard-rate-sale":
      return { title: "Standard Rated Sales", subTitle: "" };

    case "standard-rate-purchase":
      return { title: "Standard Rated Purchase", subTitle: "" };

    case "zero-rate-sale":
      return { title: "Zero Rated Sales", subTitle: "" };

    case "out-of-scope":
      return { title: "Out of Scope", subTitle: "" };

    case "zero-rate-purchase":
      return { title: "Zero Rate Purchase", subTitle: "" };

    case "exempt-sale":
      return { title: "Exempt Supplies Sale", subTitle: "" };

    case "exempt-purchase":
      return { title: "Exempt Supplies Purchase", subTitle: "" };

    case "contract-security-deposit":
      return { title: "Contract Security Deposit", subTitle: "" };

    case "vat-reconciliation":
      return { title: "Vat Reconciliation", subTitle: "" };

    default:
      return { title: reportType, subTitle: "" };
  }
};

const getReportRender = (reportType, accountingPeriod, activeCompany, permission, totalSums) => {
  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/${reportType}?CompanyId=${activeCompany.id}`, "_blank", "noopener,noreferrer");

  if (!checkIfValueExists(reportType, permission)) {
    return "";
  }

  switch (reportType) {
    case "balance-sheet":
      return (
        <ReportSheetBalanceSheet
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "profit-and-loss":
      return (
        <ReportSheetProfitLoss
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "cash-flow-statement":
      return (
        <ReportSheetCashFlow
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "journal-ledger":
      return <ReportJournalLedger />;

    case "general-journal":
      return <ReportGeneralJournal />;

    case "trial-balance":
      return (
        <ReportTrialBalance
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "tax-liability":
      return (
        <ReportTaxLiability
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
          exportExcel={exportExcel}
        />
      );

    case "profit-and-loss-vs-actual":
      return <ReportSheetProfitLossVsActual />;

    case "asset-valuation":
      return <ReportSheetAssetValuation />;

    case "fixed-asset-listing":
      return <ReportFixedAssetListing />;

    case "vat-summary":
      return (
        <ReportVATSummary activeCompany={activeCompany} />
      );

    case "returned-cheques":
      return <ReportReturnedCheque />;

    case "rent-roll":
      return (
        <ReportRentRoll
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "rent-roll-archive":
      return (
        <ReportRentRollArchive
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "opex-report":
      return (
        <ReportOpex
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "occupancy":
      return (
        <ReportOccupancy
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "vat-input":
      return (
        <ReportInputVAT
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
          exportExcel={exportExcel}
        />
      );

    case "vat-output":
      return (
        <ReportOutputVAT
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
          exportExcel={exportExcel}
        />
      );

    case "approtionment-report":
      return (
        <ReportApprotionment
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "fixed-assets-register":
      return (
        <ReportFAR
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
          exportExcel={exportExcel}
        />
      );

    case "forthcoming-renewals":
      return (
        <ReportForthComingRenewal
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
          exportExcel={exportExcel}
        />
      );

    case "tenant-statement":
      return (
        <DynamicObjectOverview
          showBreadcrumb={false}
          objectName={dynamicObjectMap.get("TenantObjectName")}
          navigationLink="tenant-statement?tenant="
          showTableOnly
          showFilters
          showActionCell={false}
        />
      );

    case "bounce-cheque":
      return (
        <ReportBounceCheque
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "clear-cheque":
      return (
        <ReportClearCheque
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "collect-cheque":
      return (
        <ReportCollectCheque
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "inventory-summary":
      return <ReportSheetInventorySummary />;

    case "agent-summary":
      return <ReportAgentSummary />;

    case "transaction-detail":
      return <ReportTransactionDetail />;

    case "vat-details":
      return (
        <ReportVATDetail
          activeCompany={activeCompany}
        />
      );

    case "vat-exceptions":
      return (
        <ReportVATException activeCompany={activeCompany} />
      );

    case "unit-ready-report":
      return (
        <ReportUnitReady
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "customer-aging":
      return (
        <ReportAging
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "vacancy-aging":
      return (
        <ReportVacancyAging
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "forthcoming-vacancy":
      return (
        <ReportForthcomingVacancy
          activeCompany={activeCompany}
        />
      );

    case "unit-availability":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("UnitObjectName")}
          navigationLink="/leasing/unit/details"
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          filters={{
            status: "Available",
          }}
          showFilters
        />
      );
    case "incident-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("IncidentReportObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );
    case "fitout-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("FitOutRequestObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );
    case "amc-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("BlanketAgreementObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          filters={{
            AgreementMethod: "AMC",
          }}
          showFilters
        />
      );
    case "facility-contract-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("FacilityContractObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );
    case "utility-contract":
      return (
        <ReportUtilityContract
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );
    case "bank-account-statement-report":
      return (
        <DynamicObjectOverview
          showBreadcrumb={false}
          objectName={dynamicObjectMap.get("BankAccountObjectName")}
          navigationLink="/finance/reports/bank-account-statement-by-id?id="
          showTableOnly
          showFilters
          showActionCell={false}
        />
      );

    case "revenue-recognition-report":
      return (
        <>
          <div className="cards-container">
            <div className="card-container">
              <ReportCard
                data={[
                  {
                    titleValue: `${formatNumber(totalSums?.totalRentalAmount || 0)}`,
                    titleColor: "success",
                    subtitle: "Total Rental Amount (AED)",
                    subtitleColor: "primary",
                  },
                  {
                    titleValue: `${formatNumber(totalSums?.recognizedRentalAmount || 0)}`,
                    titleColor: "error",
                    subtitle: "Recognized Rental Amount (AED)",
                    subtitleColor: "primary",
                    positive: true,
                  },
                  {
                    titleValue: `${formatNumber(totalSums?.pendingRentalAmount || 0)}`,
                    titleColor: "primary",
                    subtitle: "Pending Rental Amount (AED)",
                    subtitleColor: "primary",
                    positive: false,
                  },
                ]}
              />
            </div>
            <div className="card-container">
              <ReportCard
                data={[
                  {
                    titleValue: `${formatNumber(totalSums?.rentalRevenueRecognitionValue || 0)}`,
                    titleColor: "primary",
                    subtitle: "Revenue Recognition Value (AED)",
                    subtitleColor: "primary",
                  },
                  {
                    titleValue: `${formatNumber(totalSums?.totalUtilityAmount || 0)}`,
                    titleColor: "primary",
                    subtitle: "Total Utility Amount (AED)",
                    subtitleColor: "primary",
                    positive: true,
                  },
                  {
                    titleValue: `${formatNumber(totalSums?.recognizedUtilityAmount || 0)}`,
                    titleColor: "primary",
                    subtitle: "Recognized Utility Amount (AED)",
                    subtitleColor: "primary",
                    positive: false,
                  },
                ]}
              />
            </div>
            <div className="card-container">
              <ReportCard
                data={[
                  {
                    titleValue: `${formatNumber(totalSums?.pendingUtilityAmount || 0)}`,
                    titleColor: "primary",
                    subtitle: "Pending Utility Amount (AED)",
                    subtitleColor: "primary",
                  },
                  {
                    titleValue: `${formatNumber(totalSums?.utilityRevenueRecognitionValue || 0)}`,
                    titleColor: "primary",
                    subtitle: "Utility Recognition Value (AED)",
                    subtitleColor: "primary",
                    positive: true,
                  },
                ]}
              />
            </div>
          </div>
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("RevenueRecognitionRevenueRecognitionDetailObjectName")}
            components={{
              HeaderLeftContent
            }}
            tabs={[]}
            showTableOnly
            showActionCell={false}
            showFilters
          />
        </>
      );

    case "revenue-recognition-details":
      return (
        <ReportRevenueRecognitionDetail
          activeCompany={activeCompany}
        />
      );
    case "collection-report":
      return (
        <ReportCollection activeCompany={activeCompany} />
      );

    case "collection-archive-report":
      return (
        <ReportCollectChequeArchive activeCompany={activeCompany} />
      );

    case "work-order-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("WorkOrderObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "work-order-aging":
      return (
        <ReportWorkOrderAging
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "ppm-report-internal":
      return (
        <ReportPlannedVsActualPpmInhouse
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );
    case "ppm-report-external":
      return (
        <ReportPlannedVsActualPpmExternal
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );
    case "out-of-scope":
      return (
        <ReportOutOfScope
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );
    case "tenant-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("TenantObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );
    case "petty-cash-claim-by-building":
      return (
        <ReportPettyCashByBuilding
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "petty-cash-by-supervisor-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("PettyCashObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
          navigationLink="/finance/reports/petty-cash-by-supervisor-report-by-id?supervisorId="
        />
      );

    case "petty-cash-claim-categories":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("PettyCashClaimObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
          filters={{
            status: "Claimed"
          }}
          navigationLink="/finance/reports/petty-cash-claim-report-by-id?claimId="
        />
      );

    case "petty-cash-transaction-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("PettyCashTransactionObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "expense-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("ExpenseObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "expense-detail-report":
      return (
        <ReportExpenseDetails
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "daily-transaction-detail":
      return (
        <ReportDailyTransactionDetail
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "blanket-agreement-detail-report":
      return (
        <ReportBlanketAgreementDetail
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "legal-case-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("LegalCaseObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "building-cost-report":
      return (
        <ReportBuildingCost
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "building-revenue-report":
      return (
        <ReportBuildingRevenue
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "invoice-listing-report":
      return (
        <ReportInvoiceListing
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "tenant-outstanding":
      return (
        <ReportTenantOutstanding
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "tenant-outstanding-archive":
      return (
        <ReportTenantOutstandingArchive
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "tenant-aging-archive":
      return (
        <ReportAgingArchive
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "standard-rate-sale":
      return (
        <ReportStandardRatedSales
          activeCompany={activeCompany}
        />
      );

    case "standard-rate-purchase":
      return (
        <ReportStandardRatedPurchase
          activeCompany={activeCompany}
        />
      );

    case "zero-rate-sale":
      return (
        <ReportZeroRatedSales
          activeCompany={activeCompany}
        />
      );

    // Supplier Reports
    case "vendor-aging":
      return (
        <ReportSupplierAging
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "supplier-aging-archive":
      return (
        <ReportSupplierAgingArchive
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "supplier-statement-by-id":
      return (
        <ReportSupplierAging
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "supplier-paid-invoices":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("SupplierObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          navigationLink="/finance/reports/supplier-paid-invoices-by-id?id="
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "supplier-payment":
      return (
        <ReportSupplierPayment
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "supplier-aging-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("SupplierObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          navigationLink="/finance/reports/supplier-statement-by-id?id="
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "supplier-report":
      return (
        <DynamicObjectOverview
          objectName={dynamicObjectMap.get("SupplierObjectName")}
          components={{
            HeaderLeftContent
          }}
          tabs={[]}
          showTableOnly
          showActionCell={false}
          showFilters
        />
      );

    case "supplier-advance-payments":
      return (
        <ReportSupplierAdvancePayment
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "zero-rate-purchase":
      return (
        <ReportZeroRatedPurchase
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "exempt-sale":
      return (
        <ReportExemptSuppliesSale
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "exempt-purchase":
      return (
        <ReportExemptSuppliesPurchase
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "contract-security-deposit":
      return (
        <ReportContractSecurityDeposit
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    case "vat-reconciliation":
      return (
        <ReportVATReconciliation
          accountingPeriod={accountingPeriod}
          activeCompany={activeCompany}
        />
      );

    default:
      return <div />;
  }
};

function Report() {
  const { reportType } = useParams();
  const activeCompany = useSelector(selectActiveCompany);
  const [accountingPeriod, setAccountingPeriod] = useState(null);
  const permission = useSelector(selectPermissions);
  const [totalSums, setTotalSums] = useState(null);

  const { data: revenueRecognitionData } = useQuery(
    [kebabCase(dynamicObjectMap.get("RevenueRecognitionRevenueRecognitionDetailObjectName"))],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("RevenueRecognitionRevenueRecognitionDetailObjectName"))),
    {
      enabled: Boolean(reportType === "revenue-recognition-report"),
    }
  );

  const { data: accountingPeriodData } = useQuery(["accounting-period"],
    () => getAccountingPeriods());

  useEffect(() => {
    try {
      const lastIndexAccountingPeriod = accountingPeriodData?.data[
        accountingPeriodData.data.length -
         1];
      const selectedAccountingPeriod = {
        ...lastIndexAccountingPeriod,
        label: lastIndexAccountingPeriod?.name,
        value: lastIndexAccountingPeriod?.id,
      };
      setAccountingPeriod(selectedAccountingPeriod);
    } catch (error) {
      console.error("Error fetching accounting periods:", error);
    }
  }, [accountingPeriodData]);

  useEffect(() => {

    if (revenueRecognitionData?.data?.length > 0) {
      const sums = revenueRecognitionData?.data.reduce((totals, item) => {
        totals.totalRentalAmount += item.totalAmount;
        totals.recognizedRentalAmount += item.recognizedRentalAmount;
        totals.pendingRentalAmount += item.pendingRentalAmount;
        totals.rentalRevenueRecognitionValue += item.rentalRevenueRecognitionValue;
        totals.totalUtilityAmount += item.totalUtilityAmount;
        totals.recognizedUtilityAmount += item.recognizedUtilityAmount;
        totals.pendingUtilityAmount += item.pendingUtilityAmount;
        totals.utilityRevenueRecognitionValue += item.utilityRevenueRecognitionValue;
        return totals;
      }, {
        totalRentalAmount: 0,
        recognizedRentalAmount: 0,
        pendingRentalAmount: 0,
        rentalRevenueRecognitionValue: 0,
        totalUtilityAmount: 0,
        recognizedUtilityAmount: 0,
        pendingUtilityAmount: 0,
        utilityRevenueRecognitionValue: 0,
      });
      setTotalSums(sums);
    }

  }, [revenueRecognitionData]);

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title={getReportHeader(reportType).title} subtitle={getReportHeader(reportType).subTitle} icon="file-check-stroke-icon" />}
      />
      {accountingPeriod && getReportRender(reportType, accountingPeriod, activeCompany, permission, totalSums)}
    </BoxedContent>
  );
}

export default Report;
