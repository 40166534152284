import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getVatReconciliationColumns() {

  const colArr = [
    {
      label: "Reconciliation",
      value: true,
      columnId: "reconciliation",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Balance as per ledger",
      value: true,
      columnId: "ledgerBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Balance as per return",
      value: true,
      columnId: "returnBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Difference",
      value: true,
      columnId: "difference",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
