import { useMemo } from "react";
import { Input, ReactSelect } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import {
  BoxedContent, Header, CrossButton, Drawer, Select, CustomizedDatePicker
} from "@/components/common";
import FieldLabel from "../dynamic/fields/FieldLabel";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getCompanies } from "@/api/finance/companyApi";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import { recordType, agingType } from "@/utils/report/constants";

export default function ReportFilterDrawer({
  isOpen, onClose, size = 650, type = "", filters = null, setFilters = null
}) {

  const { data: objectSchema } = useQuery(
    ["dynamic-object-schema-camelized-name", kebabCase(dynamicObjectMap.get("PaymentReceiptObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("PaymentReceiptObjectName")),
    {
      enabled: type === "collection-report"
    }
  );

  const { data: unitObjectSchema } = useQuery(
    ["dynamic-object-schema-camelized-name", kebabCase(dynamicObjectMap.get("UnitObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("UnitObjectName")),
    {
      enabled: type === "occupancy" || type === "rent-roll"
    }
  );

  const { data: contractObjectSchema } = useQuery(
    ["dynamic-object-schema-camelized-name", kebabCase(dynamicObjectMap.get("ContractObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("ContractObjectName")),
    {
      enabled: type === "collection-report" || type === "utility-contract"
    }
  );

  const { data: blanketAgreementObjectSchema } = useQuery(
    ["dynamic-object-schema-camelized-name", kebabCase(dynamicObjectMap.get("BlanketAgreementObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("BlanketAgreementObjectName")),
    {
      enabled: type === "blanket-agreement"
    }
  );

  const blanketAgreementStatusOptions = useMemo(
    () => blanketAgreementObjectSchema?.document?.find((f) => f.name === "status").options || [],
    [blanketAgreementObjectSchema]
  );

  const blanketAgreementAgreementMethods = useMemo(
    () => blanketAgreementObjectSchema?.document?.find((f) => f.name === "agreementMethod").options || [],
    [blanketAgreementObjectSchema]
  );

  const unitCategory = useMemo(
    () => unitObjectSchema?.document?.find((f) => f.name === "unitCategory").options || [],
    [unitObjectSchema]
  );

  const unitType = useMemo(
    () => unitObjectSchema?.document?.find((f) => f.name === "unitType").options || [],
    [unitObjectSchema]
  );

  const unitCurrentStatus = useMemo(
    () => unitObjectSchema?.document?.find((f) => f.name === "currentStatus").options || [],
    [unitObjectSchema]
  );

  const statusOptions = useMemo(
    () => objectSchema?.document?.find((f) => f.name === "status").options || [],
    [objectSchema]
  );

  const unitTypeOptions = useMemo(
    () => unitObjectSchema?.document?.find((f) => f.name === "unitType").options || [],
    [unitObjectSchema]
  );

  const unitStatusOptions = useMemo(
    () => unitObjectSchema?.document?.find((f) => f.name === "status").options || [],
    [unitObjectSchema]
  );

  const paymentMethodOptions = useMemo(
    () => objectSchema?.document?.find((f) => f.name === "outstandingTransaction")?.fields.find((o) => o.name === "paymentMethod").options || [],
    [objectSchema]
  );

  const paymentTypeOptions = useMemo(
    () => contractObjectSchema?.document?.find((f) => f.name === "paymentDetail")?.fields.find((o) => o.name === "paymentType").options || [],
    [contractObjectSchema]
  );

  const currencies = [
    {
      label: "Canadian Dollar (CAD)",
      value: "CAD",
    },
    {
      label: "Euro (EUR)",
      value: "EUR",
    },
    {
      label: "Japanese Yen (JPY)",
      value: "Japanese Yen (JPY)",
    },
    {
      label: "UAE Dirham (AED)",
      value: "AED",
    },
    {
      label: "US Dollar (USD)",
      value: "USD",
    },
  ];

  const tenures = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },
    {
      label: "Weekly",
      value: "weekly",
    }
  ];

  const reportType = [
    { label: "Paid & Outstanding", value: "*" },
    { label: "Paid Report", value: "PaidReport" },
    { label: "Outstanding Report", value: "OutstandingReport" }
  ];

  const owners = [
    {
      label: "Anyone",
      value: "anyone",
    }
  ];

  const collectionTypes = [
    { label: "All", value: "*" },
    { label: "Payment Receipts", value: "Payment Receipts" },
    { label: "General Receipts", value: "General Receipts" }
  ];

  const renderFilters = () => {
    switch (type) {
      case "vat-details":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Location" />
              <Select
                name="locationId"
                queryKey="locationId"
                placeholder="Select location"
                value={filters?.locationId}
                onChange={(value) => setFilters({ ...filters, locationId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("LocationObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Company" />
              <Select
                name="company"
                queryKey="company"
                placeholder="Select Company"
                value={filters?.company}
                onChange={(value) => setFilters({ ...filters, company: value })}
                optionsLoader={() => getCompanies()}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      case "supplier-aging-archive":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Record Type" />
              <ReactSelect
                name="type"
                placeholder="Select Type"
                options={agingType}
                isClearable
                value={filters?.agingType}
                onChange={(value) => setFilters({ ...filters, type: value })}
              />
            </div>
          </div>
        );
      case "revenue-recognition-details":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Component Number" />
              <Input
                name="componentNumber"
                placeholder="Select Component Number"
                value={filters.componentNumber}
                onChange={(value) => setFilters({ ...filters, componentNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Contract Number" />
              <Input
                name="ContractNumber"
                placeholder="Select Contract Number"
                value={filters.contractNumber}
                onChange={(value) => setFilters({ ...filters, contractNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "vat-input":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Record Type" />
              <ReactSelect
                name="recordType"
                placeholder="Select Record Type"
                options={recordType}
                value={filters?.recordType}
                onChange={(value) => setFilters({ ...filters, recordType: value })}
              />
            </div>
          </div>
        );

      case "vat-reconciliation":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Record Type" />
              <ReactSelect
                name="recordType"
                placeholder="Select Record Type"
                options={recordType}
                value={filters?.recordType}
                onChange={(value) => setFilters({ ...filters, recordType: value })}
              />
            </div>
          </div>
        );
      case "unit-ready":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Supervisor" />
              <Select
                name="supervisorKey"
                queryKey="empolyees"
                placeholder="Select Supervisor"
                value={filters?.supervisorKey}
                onChange={(value) => setFilters({ ...filters, supervisorKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("EmployeeObjectName"))}
                apiFilters={{
                  designation: "Supervisor",
                  status: "Active"
                }}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Building Name" />
              <Select
                name="buildingKey"
                queryKey="buildingKey"
                placeholder="Select Building Name"
                value={filters?.buildingKey}
                onChange={(value) => setFilters({ ...filters, buildingKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("BuildingObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );

      case "supplier-payment":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Report Type" />
              <ReactSelect
                name="reportType"
                placeholder="Select Tenant Type"
                options={reportType}
                value={filters?.reportType}
                onChange={(value) => setFilters({ ...filters, reportType: value })}
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Supplier Name" />
              <Select
                name="supplierKey"
                queryKey="supplierKey"
                placeholder="Select Supplier Name"
                value={filters?.supplierKey}
                onChange={(value) => setFilters({ ...filters, supplierKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("SupplierObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );

      case "rent-roll":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Location" />
              <Select
                name="location"
                queryKey="location"
                placeholder="Select location"
                value={filters?.location}
                onChange={(value) => setFilters({ ...filters, location: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("LocationObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Contract Number" />
              <Select
                name="contractNumber"
                queryKey="contractNumber"
                placeholder="Select Contract Number"
                value={filters?.contractNumber}
                onChange={(value) => setFilters({ ...filters, contractNumber: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Status" />
              <ReactSelect
                name="unitStatus"
                placeholder="Select Unit Status"
                options={unitStatusOptions}
                value={filters?.unitStatus}
                onChange={(value) => setFilters({ ...filters, unitStatus: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Type" />
              <ReactSelect
                name="unitType"
                placeholder="Select Unit Type"
                options={unitType}
                value={filters?.unitType}
                onChange={(value) => setFilters({ ...filters, unitType: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Category" />
              <ReactSelect
                name="unitCategory"
                placeholder="Select Unit Category"
                options={unitCategory}
                value={filters?.unitCategory}
                onChange={(value) => setFilters({ ...filters, unitCategory: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Current Status" />
              <ReactSelect
                name="unitCurrentStatus"
                placeholder="Select Unit Current Status"
                options={unitCurrentStatus}
                value={filters?.unitCurrentStatus}
                onChange={(value) => setFilters({ ...filters, unitCurrentStatus: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant" />
              <Select
                name="tenant"
                queryKey="tenant"
                placeholder="Select Tenant"
                value={filters?.tenant}
                onChange={(value) => setFilters({ ...filters, tenant: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Building" />
              <Select
                name="building"
                queryKey="building"
                placeholder="Select Building"
                value={filters?.building}
                onChange={(value) => setFilters({ ...filters, building: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("BuildingObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit" />
              <Select
                name="unit"
                queryKey="unit"
                placeholder="Select Unit"
                value={filters?.unit}
                onChange={(value) => setFilters({ ...filters, unit: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("UnitObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );

      case "fixed-assets-register":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Asset Key" />
              <Select
                name="assetKey"
                queryKey="assetKey"
                placeholder="Select Asset Key"
                value={filters?.assetKey}
                onChange={(value) => setFilters({ ...filters, assetKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ItemObjectName"))}
                dataAccessKey="data"
                optionLabelKey="itemName"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );

      case "customer-aging":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Number" />
              <Input
                name="tenantNumber"
                placeholder="Select Tenant Number"
                value={filters.tenantNumber}
                onChange={(value) => setFilters({ ...filters, tenantNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Building Number" />
              <Input
                name="buildingNumber"
                placeholder="Select Building Number"
                value={filters.buildingNumber}
                onChange={(value) => setFilters({ ...filters, buildingNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Component Number" />
              <Input
                name="componentNumber"
                placeholder="Select Component Number"
                value={filters.componentNumber}
                onChange={(value) => setFilters({ ...filters, componentNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "contract-security-deposit":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Number" />
              <Input
                name="tenantNumber"
                placeholder="Select Tenant Number"
                value={filters.tenantNumber}
                onChange={(value) => setFilters({ ...filters, tenantNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Contract Number" />
              <Select
                name="contractNumber"
                queryKey="contractNumber"
                placeholder="Select Contract Number"
                value={filters?.contractNumber}
                onChange={(value) => setFilters({ ...filters, contractNumber: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Component Number" />
              <Select
                name="componentNumber"
                queryKey="componentNumber"
                placeholder="Select Component Number"
                value={filters?.componentNumber}
                onChange={(value) => setFilters({ ...filters, componentNumber: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ComponentObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      case "utility-contract":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Name" />
              <Select
                name="tenantName"
                queryKey="tenant"
                placeholder="Select Tenant Name"
                value={filters?.tenantName}
                onChange={(value) => setFilters({ ...filters, tenantName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      case "occupancy":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Component Name" />
              <Input
                name="componentName"
                placeholder="Component Name"
                value={filters.componentName}
                onChange={(value) => setFilters({ ...filters, componentName: value })}
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Emirate" />
              <Input
                name="emirate"
                placeholder="Emirate"
                value={filters.emirate}
                onChange={(value) => setFilters({ ...filters, emirate: value })}
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Type" />
              <ReactSelect
                name="unitType"
                placeholder="Select Unit Type"
                options={unitTypeOptions}
                value={filters?.unitType}
                onChange={(value) => setFilters({ ...filters, unitType: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit Status" />
              <ReactSelect
                name="unitStatus"
                placeholder="Select Unit Status"
                options={unitStatusOptions}
                value={filters?.unitStatus}
                onChange={(value) => setFilters({ ...filters, unitStatus: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "collection-report":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Collection Type" />
              <ReactSelect
                name="collectionType"
                placeholder="Select Collection Type"
                options={collectionTypes}
                value={filters?.collectionType}
                onChange={(value) => setFilters({ ...filters, collectionType: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>

            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant" />
              <Select
                name="tenant"
                queryKey="tenant"
                placeholder="Select Tenant"
                value={filters?.tenantName}
                onChange={(value) => setFilters({ ...filters, tenantName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Payment Method" />
              <ReactSelect
                name="paymentMethod"
                placeholder="Select Payment Method"
                options={paymentMethodOptions}
                value={filters?.paymentMethod}
                onChange={(value) => setFilters({ ...filters, paymentMethod: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Payment Type" />
              <ReactSelect
                name="paymentType"
                placeholder="Select Payment Type"
                options={paymentTypeOptions}
                value={filters?.paymentType}
                onChange={(value) => setFilters({ ...filters, paymentType: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Payment Status" />
              <ReactSelect
                name="paymentStatus"
                placeholder="Select Payment Status"
                options={statusOptions}
                value={filters?.paymentStatus}
                onChange={(value) => setFilters({ ...filters, paymentStatus: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );

      case "expense-details":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Expense Number" />
              <Select
                name="number"
                queryKey="expenseNumber"
                placeholder="Select Expense Number"
                value={filters?.expenseNumber}
                onChange={(value) => setFilters({ ...filters, expenseNumber: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ExpenseObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="number"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Supplier Number" />
              <Select
                name="number"
                queryKey="supplierId"
                placeholder="Select Supplier Number"
                value={filters?.supplierId}
                onChange={(value) => setFilters({ ...filters, supplierId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("SupplierObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="number"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Expense Type" />
              <Select
                name="name"
                queryKey="name"
                placeholder="Select Expense Type"
                value={filters?.expenseTypeId}
                onChange={(value) => setFilters({ ...filters, expenseTypeId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ExpenseTypeObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="name"
                isClearable
              />
              <div className="col-md-12 report-field">
                <FieldLabel label="Asset Name" />
                <Input
                  name="assetName"
                  placeholder="Select Asset Name"
                  value={filters.assetName}
                  onChange={(value) => setFilters({ ...filters, assetName: value })}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
          </div>
        );

      case "blanket-agreement":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Supplier Name" />
              <Select
                name="supplierKey"
                queryKey="supplierKey"
                placeholder="Select Supplier Name"
                value={filters?.supplierKey}
                onChange={(value) => setFilters({ ...filters, supplierKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("SupplierObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Agreement Method" />
              <ReactSelect
                name="agreementMethod"
                placeholder="Select Agreement Method"
                options={blanketAgreementAgreementMethods}
                value={filters?.agreementMethod}
                onChange={(value) => setFilters({ ...filters, agreementMethod: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Status" />
              <ReactSelect
                name="status"
                placeholder="Select Status"
                options={blanketAgreementStatusOptions}
                value={filters?.status}
                onChange={(value) => setFilters({ ...filters, status: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );

      case "suppliers-advance-payments-with-expense":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Supplier Name" />
              <Select
                name="supplierName"
                queryKey="supplierKey"
                placeholder="Select Supplier Name"
                value={filters?.supplierKey}
                onChange={(value) => setFilters({ ...filters, supplierKey: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("SupplierObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Payment Number" />
              <Input
                name="paymentNumber"
                placeholder="Select Payment Number"
                value={filters.paymentNumber}
                onChange={(value) => setFilters({ ...filters, paymentNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Expense Number" />
              <Input
                name="expenseNumber"
                placeholder="Select Expense Number"
                value={filters.expenseNumber}
                onChange={(value) => setFilters({ ...filters, expenseNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );

      case "tenant-outstanding":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Name" />
              <Select
                name="tenantName"
                queryKey="tenant"
                placeholder="Select Tenant Name"
                value={filters?.tenantName}
                onChange={(value) => setFilters({ ...filters, tenantName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Number" />
              <Input
                name="tenantNumber"
                placeholder="Select Tenant Number"
                value={filters.tenantNumber}
                onChange={(value) => setFilters({ ...filters, tenantNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Invoice Number" />
              <Input
                name="invoiceNumber"
                placeholder="Select Invoice Number"
                value={filters.invoiceNumber}
                onChange={(value) => setFilters({ ...filters, invoiceNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "tenant-statement":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Name" />
              <Select
                name="tenantName"
                queryKey="tenant"
                placeholder="Select Tenant Name"
                value={filters?.tenantId}
                onChange={(value) => setFilters({ ...filters, tenantId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      case "clear-cheque":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Name" />
              <Select
                name="tenantName"
                queryKey="tenant"
                placeholder="Select Tenant Name"
                value={filters?.tenantName}
                onChange={(value) => setFilters({ ...filters, tenantName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant ID" />
              <Input
                name="tenantId"
                placeholder="Select Tenant ID"
                value={filters.tenantKey}
                onChange={(value) => setFilters({ ...filters, tenantKey: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Component Name" />
              <Input
                name="componentName"
                placeholder="Component Name"
                value={filters.componentName}
                onChange={(value) => setFilters({ ...filters, componentName: value })}
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Component ID" />
              <Input
                name="componentID"
                placeholder="Select Component ID"
                value={filters.componentKey}
                onChange={(value) => setFilters({ ...filters, componentKey: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Bank Name" />
              <Input
                name="bankName"
                placeholder="Select Bank Name"
                value={filters.bankName}
                onChange={(value) => setFilters({ ...filters, bankName: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Bank Number" />
              <Input
                name="bankNumber"
                placeholder="Select Bank Number"
                value={filters.bankKey}
                onChange={(value) => setFilters({ ...filters, bankKey: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Cleared Bank Name" />
              <Input
                name="clearedBankName"
                placeholder="Select Cleared Bank Name"
                value={filters.clearedBankName}
                onChange={(value) => setFilters({ ...filters, clearedBankName: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Cleared Bank Code" />
              <Input
                name="clearedBankCode"
                placeholder="Select Cleared Bank Code"
                value={filters.clearedBankCode}
                onChange={(value) => setFilters({ ...filters, clearedBankCode: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "bounce-cheque":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant Name" />
              <Select
                name="tenantName"
                queryKey="tenant"
                placeholder="Select Tenant Name"
                value={filters?.tenantName}
                onChange={(value) => setFilters({ ...filters, tenantName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Tenant ID" />
              <Input
                name="tenantId"
                placeholder="Select Tenant ID"
                value={filters.tenantKey}
                onChange={(value) => setFilters({ ...filters, tenantKey: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Component Name" />
              <Select
                name="componentName"
                queryKey="component"
                placeholder="Select Component Name"
                value={filters?.componentName}
                onChange={(value) => setFilters({ ...filters, componentName: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ComponentObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Component Number" />
              <Select
                name="componentNumber"
                queryKey="componentNumber"
                placeholder="Select Component Number"
                value={filters?.componentNumber}
                onChange={(value) => setFilters({ ...filters, componentNumber: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ComponentObjectName"))}
                dataAccessKey="data"
                optionLabelKey="number"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Payment Receipt Number" />
              <Input
                name="paymentReceiptNumber"
                placeholder="Select Payment Receipt Number"
                value={filters.paymentReceiptNumber}
                onChange={(value) => setFilters({ ...filters, paymentReceiptNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Cheque Due Date" />
              <CustomizedDatePicker
                name="chequeDueDate"
                value={filters.chequeDueDate}
                onChange={(value) => setFilters({ ...filters, chequeDueDate: value })}
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Cheque Amount" />
              <Input
                name="chequeAmount"
                placeholder="Select Cheque Amount"
                value={filters.chequeAmount}
                onChange={(value) => setFilters({ ...filters, chequeAmount: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
      case "ppm-report-internal":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Work Order Number" />
              <Input
                name="workOrderNumber"
                placeholder="Select Work Order Number"
                value={filters.workOrderNumber}
                onChange={(value) => setFilters({ ...filters, workOrderNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field select-field ">
              <FieldLabel label="Supervisor" />
              <Select
                name="supervisor"
                queryKey="employees"
                placeholder="Select Supervisor"
                value={filters?.supervisorId}
                onChange={(value) => setFilters({ ...filters, supervisorId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("EmployeeObjectName"))}
                apiFilters={{
                  designation: "Supervisor",
                  status: "Active"
                }}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Building Name" />
              <Select
                name="buildingName"
                queryKey="buildingName"
                placeholder="Select Building Name"
                value={filters?.buildingId}
                onChange={(value) => setFilters({ ...filters, buildingId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("BuildingObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Category" />
              <Select
                name="category"
                queryKey="category"
                placeholder="Select Category"
                value={filters?.categoryId}
                onChange={(value) => setFilters({ ...filters, categoryId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("CategoryObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      case "work-order-aging":
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12 report-field">
              <FieldLabel label="Work Order Number" />
              <Input
                name="workOrderNumber"
                placeholder="Select Work Order Number"
                value={filters.workOrderNumber}
                onChange={(value) => setFilters({ ...filters, workOrderNumber: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Building Name" />
              <Select
                name="buildingName"
                queryKey="buildingName"
                placeholder="Select Building Name"
                value={filters?.buildingId}
                onChange={(value) => setFilters({ ...filters, buildingId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("BuildingObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Category" />
              <Select
                name="category"
                queryKey="category"
                placeholder="Select Category"
                value={filters?.categoryId}
                onChange={(value) => setFilters({ ...filters, categoryId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("CategoryObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Unit" />
              <Select
                name="unit"
                queryKey="unit"
                placeholder="Select Unit"
                value={filters?.unitId}
                onChange={(value) => setFilters({ ...filters, unitId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("UnitObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Service Request" />
              <Select
                name="serviceRequest"
                queryKey="serviceRequest"
                placeholder="Select Service Request"
                value={filters?.serviceRequestId}
                onChange={(value) => setFilters({ ...filters, serviceRequestId: value })}
                optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("ServiceRequestObjectName"))}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
              />
            </div>
          </div>
        );
      default:
        return (
          <div className="parent-container quotation-drawer report-drawer">
            <div className="col-md-12">
              <FieldLabel label="Report Tenure" />
              <ReactSelect
                name="tenure"
                placeholder="Select Tenure"
                options={tenures}
                value={filters.tenure}
                onChange={(value) => setFilters({ ...filters, tenure: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Currency" />
              <ReactSelect
                name="currency"
                placeholder="Select Currency"
                options={currencies}
                value={filters.currency}
                onChange={(value) => setFilters({ ...filters, currency: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Owner" />
              <ReactSelect
                name="owner"
                placeholder="Select Owner"
                options={owners}
                value={filters.owner}
                onChange={(value) => setFilters({ ...filters, owner: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Title Has Words" />
              <Input
                name="title"
                placeholder="Select Account"
                value={filters.title}
                onChange={(value) => setFilters({ ...filters, title: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
            <div className="col-md-12 report-field">
              <FieldLabel label="Date Posted" />
              <Input
                name="datePosted"
                placeholder="dd/MM/yyyy"
                value={filters.datePosted}
                onChange={(value) => setFilters({ ...filters, datePosted: value })}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
        );
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      size={size}
      className="drawer-border drawer-profile-border"
    >
      <BoxedContent className="quotation-drawer">
        <Header
          leftContent={<h1 className="">Filters</h1>}
          rightContent={<CrossButton onClick={() => onClose(false)} />}
        />
        {renderFilters()}
      </BoxedContent>
    </Drawer>
  );
}
