import { getApprotionmentColumns, getApprotionmentRows } from "./approtionment-statement";
import { getVatInputApportionment } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportApprotionment({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-input-apportionment"
      activeCompany={activeCompany}
      columnsDataProvider={getApprotionmentColumns}
      rowsDataProvider={getApprotionmentRows}
      fetchData={getVatInputApportionment}
      exportUrl="reports/export-query-report/vat-input-apportionment"
      filtersInitialState={{}}
      orderBy="BuildingNumber"
    />
  );
}
