import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase, startCase } from "lodash";
import { addDays } from "date-fns";

import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import showToast from "@/utils/toast/helpers";
import { DynamicFormContainer, CustomActionDropdown } from "@/components/dynamic";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getPurchaseOrderTableColumns, getExpensePaymentTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { useCompanyAccount, useTaskRedirect, useModal } from "@/hooks";

const formatGrn = (grn) => {
  const data = {
    purchaseOrder: {
      label: grn.purchaseOrder.number,
      value: grn.purchaseOrder.id,
    },
  };

  return data;
};

const formatPurchaseOrder = (purchaseOrder, expenseAccount) => {
  const {
    id,
    number,
    supplier,
    project,
    currency,
    priceList,
    company,
    itemDetail,
    discount,
    discountType,
    subtotal,
    total,
    barcode,
    itemType,
  } = purchaseOrder;

  const data = {
    key: id,
    initiationType: {
      label: "P2p",
      value: "P2p"
    }
  };

  const isFixedAsset = itemType === "Asset";

  data.itemType = {
    label: itemType,
    value: itemType,
  };

  data.purchaseOrder = {
    label: number,
    value: id,
  };

  const { tRN, name, id: supplierId } = supplier;
  data.supplier = {
    label: name,
    value: supplierId,
    ...supplier,
  };

  data.tRN = tRN;

  data.currency = {
    label: currency?.name,
    value: currency?.id,
  };

  if (priceList && !isEmpty(priceList)) {
    data.priceList = {
      label: priceList?.name,
      value: priceList?.id,
    };
  }

  if (project && !isEmpty(project)) {
    data.project = {
      label: project?.name,
      value: project?.id,
    };
  }

  data.company = {
    label: company?.name,
    value: company?.id,
  };

  data.itemDetail = itemDetail?.map((item) => {
    const lineItem = {
      ...item,
      tax: {
        label: item.tax?.name,
        value: item.tax?.id,
      },
    };

    if (isFixedAsset) {
      lineItem.label = item.label;
    } else {
      lineItem.item = {
        label: item.item?.itemName,
        value: item.item?.id,
      };

      if (item.expenseAccount && !isEmpty(item.expenseAccount)) {
        lineItem.account = {
          label: item.expenseAccount.name,
          value: item.expenseAccount.id,
        };
      } else {
        lineItem.account = expenseAccount;
      }
    }

    return lineItem;
  });

  data.discount = discount;
  data.barcode = barcode;
  data.discountType = {
    label: startCase(discountType),
    value: discountType,
  };
  data.subtotal = subtotal;
  data.total = total;

  data.openBalance = total;

  data.status = {
    label: "Pending",
    value: "Pending",
  };

  return data;
};

const purchaseOrderInitialState = {
  itemDetail: [],
  subtotal: "",
  discount: "",
  discountType: null,
  total: "",
};

const calculateDiscountAmount = (discountType, discount, itemAmount, noOfItems) => {
  if (discountType === "Amount") {
    return parseFloat(discount / noOfItems) || 0;
  }

  if (discountType === "Percentage") {
    return (parseFloat(itemAmount) * parseFloat(discount || 0)) / 100 || 0;
  }
  return 0;
};

function HeaderRightContent({
  openModal, isPaymentSelected, setIsPaymentSelected, showButton, ...rest
}) {
  const onPaymentClick = () => {
    setIsPaymentSelected(true);
    openModal();
  };

  const onPurchaseOrderClick = () => {
    setIsPaymentSelected(false);
    openModal();
  };
  const actions = [
    {
      title: "Select Purchase Orders",
      onClick: () => { onPurchaseOrderClick(); },
      icon: "document-icon"
    },
    {
      title: "Select Advance Payments",
      onClick: () => { onPaymentClick(); },
      icon: "bag-icon"
    }
  ];
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <div className="action-cell">
          <CustomActionDropdown
            actions={actions}
            trigger={(
              <div className="action-dropdown-trigger">
                <span className="text">Actions</span>
                <span className="material-icons-outlined">expand_more</span>
              </div>
            )}
            testId="Add-GL-Action-Button"
          />
        </div>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function PurchaseInvoiceForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [toastId, setToastId] = useState();
  const [initialState, setInitialState] = useState({});
  const [paymentDetailsTableData, setPaymentDetailsTableData] = useState([]);
  const [purchaseOrderTableData, setPurchaseOrderTableData] = useState([]);
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const { isOpen, closeModal, openModal } = useModal(false);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();

  const { data: paymentData, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PaymentObjectName")),
      "PartiallyAdjusted,Posted",
      state?.supplier?.value,
    ],
    () =>
      getDynamicObjectRecords(
        dynamicObjectMap.get("PaymentObjectName"),
        {
          paymentType: "SupplierAdvance",
          payee: state?.supplier?.value,
          "status[in]": "PartiallyAdjusted,Posted",
          queryMode: "Deep",
        },
        {
          enabled: Boolean(state?.supplier?.value),
        }
      )
  );

  const formatPayment = (payments) =>
    payments.map((item) => ({
      ...item,
      advanceAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      adjustmentAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      key: item.number,
      isSelected: false,
    }));

  useEffect(() => {
    if ((paymentData || isLoading)) {
      if (!state?.supplier?.value) return;
      if (isLoading && isPaymentSelected) {
        setToastId(toast.loading("Loading Supplier Advance..."));
      }
      if (!paymentData) return;
      const paymentDetailData = formatPayment(paymentData.data);

      if (!paymentDetailData.length) {
        toast.dismiss(toastId);
        showToast("No Supplier Advance found for the given supplier!", "info");
      } else {
        toast.dismiss(toastId);
        showToast("Supplier Advance loaded!", "success");
      }
      ref.current.setFormValue("tRN", state?.supplier?.tRN);
      setPaymentDetailsTableData(paymentDetailData);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [paymentData, state?.supplier?.value, isLoading]);

  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  const goodsReceiptNote = searchParams.get("goodsReceiptNote");
  const purchaseOrderId = searchParams.get("purchaseOrder");

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);
  const { data: grnData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GoodsReceiptNoteObjectName")), goodsReceiptNote],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("GoodsReceiptNoteObjectName")),
        goodsReceiptNote
      ),
    {
      enabled: Boolean(goodsReceiptNote),
    }
  );

  useEffect(() => {
    if (grnData) {
      const formattedGrn = formatGrn(grnData);
      setState((prevState) => ({
        ...prevState,
        purchaseOrder: formattedGrn.purchaseOrder,
      }));
    }
  }, [grnData]);

  const { data: purchaseOrdersData } = useQuery(
    [kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")), {
        supplier: state?.supplier?.value,
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value && !goodsReceiptNote),
    }
  );

  const { data: purchaseOrderData, isLoading: isLoadingPurchaseOrder } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")),
      purchaseOrderId || state?.purchaseOrder?.value,
    ],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("PurchaseOrderObjectName"),
        purchaseOrderId || state?.purchaseOrder?.value
      ),
    {
      enabled: Boolean(state?.purchaseOrder?.value) || Boolean(purchaseOrderId),
    }
  );

  useEffect(() => {
    if (
      (purchaseOrdersData?.data &&
        purchaseOrdersData?.data?.length &&
        state?.supplier &&
        defaultAccounts) || isLoadingPurchaseOrder
    ) {

      toast.dismiss(toastId);
      let toastIdValue;
      if (isLoadingPurchaseOrder && !isPaymentSelected && state.supplier) {
        toastIdValue = toast.loading("Loading Purchase Order...");
        setToastId(toastIdValue);
      }
      if (!purchaseOrdersData?.data) return;
      if (!purchaseOrdersData?.data?.length) {
        showToast("No Purchase Order found for the given supplier!", "info");
        toast.dismiss(toastIdValue);
      } else {
        toast.dismiss(toastIdValue);
        showToast("Purchase Order loaded!", "success");
      }
      const formattedPurchaseOrders = purchaseOrdersData?.data.map((r) =>
        formatPurchaseOrder(r, defaultAccounts?.materialExpense)
      );
      toast.dismiss(toastIdValue);
      setPurchaseOrderTableData(formattedPurchaseOrders);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [purchaseOrdersData, state?.supplier, defaultAccounts]);

  useEffect(() => {
    if (purchaseOrderData && state?.purchaseOrder && defaultAccounts) {
      const formattedPurchaseOrder = formatPurchaseOrder(
        purchaseOrderData,
        defaultAccounts?.materialExpense
      );
      setInitialState(formattedPurchaseOrder);
    }
  }, [purchaseOrderData, state?.purchaseOrder, defaultAccounts]);

  const calculateTaxForItems = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      discount, discountType, itemDetail, amountOfTax
    } = formState;

    if (itemDetail && itemDetail.length > 0) {
      const updatedItemDetail = itemDetail.map(({ amount, ...rest }) => {
        const discountAmount = calculateDiscountAmount(
          discountType.value,
          discount,
          amount,
          itemDetail.length
        );
        const discountedAmount = amount - discountAmount;
        const taxFinal = taxRulesData?.data.find((i) => i.name === rest?.tax?.label);
        const parameters = {
          amount: Number(discountedAmount),
          amountOfTax,
          tax: taxFinal,
          taxAmount: ""
        };
        const { taxAmount, principalAmount } = calculateTax(parameters);
        return {
          ...rest,
          amount,
          discountAmount,
          taxAmount,
          totalAmount: formatDecimalValues(Number(taxAmount) + Number(principalAmount)),
        };
      });
      ref.current.setFormValue("itemDetail", updatedItemDetail);
      return updatedItemDetail;
    }
    return itemDetail;
  };

  const setAdvanceAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const {
      advanceAmount, supplierBalance, subtotal, total
    } = formState;

    let openBalance = total;

    if (!supplierBalance || !subtotal) {
      ref.current.setFormState({
        advanceAmount: 0,
        openBalance,
      });
      return;
    }

    const isAmountMoreThanTotal = advanceAmount > subtotal;
    const isAmountMoreThanBalance = advanceAmount > supplierBalance;

    if (isAmountMoreThanBalance) {
      openBalance = total - supplierBalance;
      ref.current.setFormState({
        advanceAmount: supplierBalance,
        openBalance,
      });
      return;
    }

    if (isAmountMoreThanTotal) {
      openBalance = total - subtotal;
      ref.current.setFormState({
        advanceAmount: subtotal,
        openBalance,
      });
      return;
    }

    openBalance = total - Number(advanceAmount);
    ref.current.setFormValue("openBalance", openBalance);
  };

  const setTotalWithDiscount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { discount, discountType } = formState;
    const updatedItems = calculateTaxForItems(key, value);

    if (!updatedItems) {
      return;
    }

    const subtotal = updatedItems?.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
      0
    );

    const taxAmount = updatedItems?.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.taxAmount),
      0
    );

    const totalAmount = formatDecimalValues(Number(subtotal) + Number(taxAmount));

    const data = {
      subtotal,
      discountedSubtotal: subtotal,
      taxAmount,
      total: totalAmount,
      openBalance: totalAmount,
    };

    if (!discount || !discountType) {
      ref.current.setFormState(data);
      return;
    }

    if (discountType.value === "Amount") {
      const discountedSubtotal = Number(subtotal) - Number(discount);

      data.discountedSubtotal = discountedSubtotal;
    }

    if (discountType.value === "Percentage") {
      const discountedSubtotal = subtotal - (subtotal * discount) / 100;
      data.discountedSubtotal = discountedSubtotal;
    }

    const total = data.discountedSubtotal + data.taxAmount;
    data.total = total;
    data.openBalance = total;

    ref.current.setFormState(data);
    setAdvanceAmount(key, value);
  };

  const setTaxType = (key, amountOfTax) => {
    const formState = ref.current.getState();
    const {
      discountType, discount, itemDetail, itemDetailTable
    } = formState;

    const finalItemDetail = itemDetail?.map((item) => {
      let finalAmount = item.amount;
      if (discountType && discount) {
        const { amount } = item;
        const discountAmount = calculateDiscountAmount(
          discountType.value,
          discount,
          amount,
          itemDetail.length
        );
        finalAmount = amount - discountAmount;
      }
      const taxFinal = taxRulesData?.data.find((i) => i.name === item?.tax?.label);
      const parameters = {
        amount: Number(finalAmount),
        amountOfTax,
        tax: taxFinal,
        taxAmount: ""
      };
      const { taxAmount, principalAmount } = calculateTax(parameters);
      return {
        ...item,
        totalAmount: formatDecimalValues(Number(principalAmount) + Number(taxAmount)),
        taxAmount,
      };
    });
    let finalItemDetailTable = {};
    if (itemDetailTable && itemDetailTable.tax) {
      const taxFinal = taxRulesData?.data.find((i) => i.name === itemDetailTable?.tax?.label);
      const parameters = {
        amount: Number(itemDetailTable.amount),
        amountOfTax,
        tax: taxFinal,
        taxAmount: ""
      };
      const { taxAmount, principalAmount } = calculateTax(parameters);
      finalItemDetailTable = {
        ...itemDetailTable,
        totalAmount: formatDecimalValues(Number(principalAmount) + Number(taxAmount)),
        taxAmount,
      };
    }
    ref.current.setFormState({
      itemDetail: finalItemDetail,
      itemDetailTable: finalItemDetailTable,
    });
  };

  const setDueDate = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { supplier } = formState;

    if (!supplier || !value) return;

    const { paymentTerm } = supplier;

    let dueDate = null;

    if (paymentTerm) {
      const { netDueInDay: paymentTermDueDays } = paymentTerm;

      if (paymentTermDueDays) {
        dueDate = addDays(new Date(value), paymentTermDueDays);
      }
    }

    ref.current.setFormState({
      dueDate,
    });
  };

  const adjustAmountsAndSum = (arr) => {
    let totalAdjustmentAmount = 0;

    arr.forEach((item) => {
      if (item.adjustmentAmount > item.advanceAmount) {
        item.adjustmentAmount = item.advanceAmount;
      }
      totalAdjustmentAmount += Number(item.adjustmentAmount);
    });

    return {
      adjustedArray: arr,
      totalAdjustmentAmount
    };
  };

  const onStateChange = (key, value) => {

    if (key === "advancePayment") {
      const result = adjustAmountsAndSum(value);
      ref.current.setFormState({ advanceAmount: result.totalAdjustmentAmount });
    }

    if (key === "supplier") {
      setState({
        ...state,
        [key]: value,
      });

      if (!value) {
        ref.current.setFormState(purchaseOrderInitialState);
      } else {
        ref.current.setFormState({
          tRN: value?.tRN,
          supplierBalance: value?.openingBalance ?? 0
        });
      }
    }

    if (key === "purchaseOrder") {
      setState({
        ...state,
        [key]: value,
      });
    }

    if (key === "amountOfTax") {
      setTaxType(key, value);
    }

    if (
      key === "discount" ||
      key === "discountType" ||
      key === "itemDetail" ||
      key === "itemDetailTable"
    ) {
      setTotalWithDiscount(key, value);
    }

    if (key === "supplierInvoiceDate") {
      setDueDate(key, value);
    }

    if (key === "advanceAmount") {
      setAdvanceAmount(key, value);
    }
  };

  const getTaxByBuildingType = (buildingType) => {
    let tax = null;
    switch (buildingType) {
      case "Residential":
        tax = taxRulesData?.data.find((i) => i.name === "Input VAT Non-recoverable - Residential (5%)");
        break;

      case "Commercial":
        tax = taxRulesData?.data.find((i) => i.name === "Input VAT Recoverable - Commercial (5%)");
        break;

      case "ResidentialAndCommercial":
        taxRulesData?.data.find((i) => i.name === "Input VAT Partial-recoverable Admin (5%)");
        break;

      default:
        break;
    }

    if (tax) {
      tax.label = tax.name;
      tax.value = tax.id;
    }

    return tax;
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};
    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }
    if (parentField === "itemDetail") {
      switch (key) {
        case "item": {
          const { asset } = formState;
          parentFieldState.asset = asset;
          if (asset?.lookupObjectName === "Building") {
            const selectedBuilding = asset;
            const { unitProperty } = selectedBuilding;
            parentFieldState.tax = getTaxByBuildingType(unitProperty);
          }

          if (value) {
            const { expenseAccount } = value;
            if (expenseAccount && !isEmpty(expenseAccount)) {
              parentFieldState.account = {
                label: expenseAccount.name,
                value: expenseAccount.id,
              };
            } else {
              parentFieldState.account = state.expenseAccount;
            }
          }
          break;
        }

        case "asset": {
          parentFieldState[key] = value;
          const { asset } = parentFieldState;

          if (asset) {
            const { unitProperty } = asset;
            parentFieldState.asset = asset;
            parentFieldState.tax = getTaxByBuildingType(unitProperty);
          }
          break;
        }

        case "quantity":
        case "rate":
        case "tax": {
          parentFieldState[key] = value;
          const { quantity, rate, tax } = parentFieldState;

          const amount = formatDecimalValues(quantity * rate);
          parentFieldState.amount = amount;
          parentFieldState.discountedAmount = amount;

          const {
            amountOfTax, discountType, discount, itemDetail
          } = formState;

          if (discountType && discount) {
            const discountAmount = calculateDiscountAmount(
              discountType,
              discount,
              amount,
              itemDetail.length
            );
            parentFieldState.discountAmount = discountAmount;
            parentFieldState.discountedAmount = amount - discountAmount;
          }

          if (tax && amountOfTax) {
            const parameters = {
              amount: parentFieldState.discountedAmount,
              amountOfTax,
              tax,
              taxAmount: ""
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount = formatDecimalValues(
              Number(principalAmount) + Number(taxAmount));
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const handleConfirm = () => {
    if (purchaseOrderTableData.length && !isPaymentSelected) {
      const selectedPurchaseOrder = purchaseOrderTableData.find((q) => q.isSelected);
      console.log("selectedPurchaseOrder:", selectedPurchaseOrder);
      if (selectedPurchaseOrder) {
        ref.current.setFormState(selectedPurchaseOrder);
      }
    }

    if (paymentDetailsTableData.length && isPaymentSelected) {
      const selectedPayments = paymentDetailsTableData.filter((q) => q.isSelected);
      const result = adjustAmountsAndSum(selectedPayments);
      if (selectedPayments.length) {
        ref.current.setFormState({
          advancePayment: selectedPayments.map((item) => ({
            payment: {
              label: item.number,
              value: item.id
            },
            advanceAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
            adjustmentAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
          })),
          advanceAmount: result.totalAdjustmentAmount
        },
        );
      }
    }
    closeModal();
  };

  const onBeforeSave = () => {
    const formState = ref.current.getState();
    if (formState.advanceAmount > (formState.total || 0)) {
      toast.error(
        "Total advance amount should be equal to or less than total amount.");
      return false;
    }
    return true;
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Supplier Purchase Order"
        subtitle="Selected supplier has following open purchase orders"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {purchaseOrderTableData.length && !isPaymentSelected ? (
          <TableWithCheckbox
            data={purchaseOrderTableData}
            columns={getPurchaseOrderTableColumns()}
            setData={setPurchaseOrderTableData}
          />
        ) : null}
        {paymentDetailsTableData.length && isPaymentSelected ? (
          <TableWithCheckbox
            data={paymentDetailsTableData}
            searchKey={["number"]}
            columns={getExpensePaymentTableColumns()}
            setData={setPaymentDetailsTableData}
            allowMultiple
            selectAll
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("PurchaseInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        onBeforeSave={onBeforeSave}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              isPaymentSelected,
              setIsPaymentSelected,
              showButton: Boolean(paymentDetailsTableData.length),
              ...props,
            }),
        }}
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
      />
    </BoxedContent>
  );
}

export default PurchaseInvoiceForm;
