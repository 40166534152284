import { getColumnWidth, estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getApprotionmentColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 7;

  const colArr = [
    {
      label: "Building Number", value: true, columnId: "buildingNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Building Name", value: true, columnId: "buildingName", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Commerical", value: true, columnId: "commerical", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Residential", value: true, columnId: "residential", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Admin", value: true, columnId: "admin", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Percentage", value: true, columnId: "percentage", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Revised Commerical", value: true, columnId: "revisedCommerical", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    },
    {
      label: "Revised Residential", value: true, columnId: "revisedResidential", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || 0)) : "")
    }
  ];

  return colArr;
}
