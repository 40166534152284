import { getSupplierAgingArchiveRows, getSupplierAgingArchiveColumns } from "./supplier-aging-archive-statement";
import { getSupplierAgingArchive } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportSupplierAgingArchive({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vendor-aging-archive"
      activeCompany={activeCompany}
      columnsDataProvider={getSupplierAgingArchiveColumns}
      rowsDataProvider={getSupplierAgingArchiveRows}
      fetchData={getSupplierAgingArchive}
      exportUrl="reports/export-query-report/vendor-aging-archive"
      filtersInitialState={{}}
      orderBy="DueDateBucket"
      showDateFilter
      showDateRangeFilter={false}
    />
  );
}
