import { getVatReconciliationRows, getVatReconciliationColumns } from "./vat-reconciliation";
import { getVatReconciliation } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const vATReconciliationFilters = {
  RecordType: "*",
};

const vATReconciliationOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];
export default function ReportVATReconciliation({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-reconciliation"
      activeCompany={activeCompany}
      columnsDataProvider={getVatReconciliationColumns}
      rowsDataProvider={getVatReconciliationRows}
      fetchData={getVatReconciliation}
      exportUrl="reports/export-query-report/vat-reconciliation"
      filtersInitialState={vATReconciliationFilters}
      filterOptions={vATReconciliationOptions}
      orderBy="AccountName"
    />
  );
}
