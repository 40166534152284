import { getColumnWidth, estimatedColumnWidth } from "../helpers";
import { formatDate, formatCurrency } from "@/utils/helpers";

export default function getFarColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 28;

  const colArr = [
    {
      label: "Building", value: true, columnId: "building", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component", value: true, columnId: "component", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit No", value: true, columnId: "unitNr", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Supplier No", value: true, columnId: "supplierNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Name", value: true, columnId: "supplierName", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Purchase Invoice Number", value: true, columnId: "purchaseInvoiceNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Category", value: true, columnId: "assetCategory", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Asset Number", value: true, columnId: "assetNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Description", value: true, columnId: "assetDescription", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Status (Active/ Non Active)", value: true, columnId: "assetStatus", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Model", value: true, columnId: "model", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Serial Number", value: true, columnId: "serialNr", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Purchased Date", value: true, columnId: "purchaseDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Sold Date", value: true, columnId: "soldDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Purchase Cost", value: true, columnId: "purchaseCosts", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Book Value", value: true, columnId: "bookValue", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Transfer Date", value: true, columnId: "transferDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "GL Code GST", value: true, columnId: "gLCodeCost", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "GL Code Cum Dept", value: true, columnId: "gLCodeCumDep", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "GL Code Dep", value: true, columnId: "gLCodeDep", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Initial Cost", value: true, columnId: "initialCosts", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Additional Cost", value: true, columnId: "additionalCost", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Revaluation [latest]", value: true, columnId: "revaluation", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Total Cost", value: true, columnId: "totalCosts", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Disposal Asset Received Amount", value: true, columnId: "disposalAssetReceivedAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Gain Loss On Disposal", value: true, columnId: "gainLossOnDisposal", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Historical Cost", value: true, columnId: "historicalCosts", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Depreciation Month To Date", value: true, columnId: "DepreciationMonthToDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Depreciation Year To Date", value: true, columnId: "depreciationYearToDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "LifeTime To Date Depreciation", value: true, columnId: "lifeTimeToDateDepreciation", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Useful Life", value: true, columnId: "usefulLife", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
  ];

  return colArr;
}
