import {
  useCallback, useState, useEffect, useMemo
} from "react";
import PropTypes from "prop-types";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, FormControl } from "@hydra/atom/components";
import { pascalize } from "humps";
import { useNavigate } from "react-router-dom";
import {
  CustomizedDatePicker,
  DynamicObjectSearchSelectField,
  Select,
  SvgIcon,
} from "@/components/common";

import { spreadSheetSelectStyles } from "@/utils/select/constants";
import { createTransfer } from "@/api/finance/transferApi";
import { getJournalEntryNumber } from "@/api/finance/journalEntryApi";
import { buildTree, flattenTree, formatGLAccountOption } from "@/utils/finance/helpers";
import { getCurrencies } from "@/api/finance/currencyApi";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { selectActiveCompany } from "@/store/appSlice";
import showToast from "@/utils/toast/helpers";
import {
  getTestId,
  formatDecimalValues,
  formatApiPayloadDate,
  formatNumber,
} from "@/utils/helpers";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { selectAutoNumber, setAutoNumber } from "@/store/autoNumberSlice";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import { checkDateInAccountingPeriod } from "@/api/finance/accountingPeriodApi";

const getInitialData = () => [
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
  {
    account: null,
    tax: null,
    debit: "",
    credit: "",
    memo: "",
    costCenter: null,
    billable: false,
  },
];

const prepareTransfers = ({ transactions, entryNumber }) => {
  const transfers = transactions.map((transaction) => {
    const isDebitTransaction = Boolean(transaction.debit);
    let debitAccountId = "";
    let creditAccountId = "";
    let debitCurrencyCode = "";
    let creditCurrencyCode = "";

    if (isDebitTransaction) {
      debitAccountId = transaction?.account?.value;
      debitCurrencyCode = "AED";
    } else {
      creditAccountId = transaction?.account?.value;
      creditCurrencyCode = "AED";
    }

    const data = {
      name: `${isDebitTransaction ? "Debit" : "Credit"}`,
      number: entryNumber,
      debitAccountId,
      debitCurrencyCode,
      creditAccountId,
      creditCurrencyCode,
      amount: isDebitTransaction ?
        -Math.round(transaction.debit * 100) :
        Math.round(transaction.credit * 100),
      code: transaction?.account?.label,
      description: transaction.memo,
      costCenters: [],
    };

    const selectedCostCenterKeys = transaction?.costCenter ?
      Object.keys(transaction?.costCenter).filter((key) => transaction?.costCenter[key]) :
      [];

    selectedCostCenterKeys.forEach((key) => {
      data.costCenters.push(transaction?.costCenter[key].value);
    });

    return data;
  });

  return transfers;
};

function IndicatorSeparator() {
  return null;
}

function NumberInput({
  value, onChange, onBlur, testId, disabled, ...rest
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    onBlur();
  };

  if (isEditing) {
    return (
      <input
        type="number"
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        min="0"
        disabled={disabled}
        data-testid={getTestId(testId)}
        {...rest}
      />
    );
  }

  return (
    <input
      type="text"
      value={value ? new Intl.NumberFormat().format(value) : ""}
      onFocus={() => setIsEditing(true)}
      readOnly={disabled}
      data-testid={getTestId(testId)}
    />
  );
}

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  testId: PropTypes.string,
};

NumberInput.defaultProps = {
  value: "",
  onChange: () => {},
  onBlur: () => {},
  testId: "",
};

function DebitCell({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateMyData,
  readOnly,
}) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <NumberInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={readOnly || original.credit}
      testId={getTestId(`GeneralJournal-${pascalize(id)}-Input-${index}`)}
    />
  );
}

function CreditCell({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateMyData,
  readOnly,
}) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <NumberInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={readOnly || original.debit}
      testId={getTestId(`GeneralJournal-${pascalize(id)}-Input-${index}`)}
    />
  );
}

function TextCell({
  value, row: { index }, column: { id }, updateMyData, readOnly
}) {
  const onChange = (e) => {
    updateMyData(index, id, e.target.value);
  };

  return (
    <input
      value={value}
      onChange={onChange}
      data-testid={getTestId(`GeneralJournal-${pascalize(id)}-Input-${index}`)}
      disabled={readOnly}
    />
  );
}

function CheckboxCell({
  value, row: { index, original }, column: { id }, updateMyData
}) {
  const { account } = original;
  const onChange = () => {
    updateMyData(index, id, !value);
  };

  const isDisabled = account ? account.accountType !== "AccountsReceivable" : true;

  return (
    <div className="d-flex justify-content-center">
      <input
        type="checkbox"
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        data-testid={getTestId(`GeneralJournal-${pascalize(id)}-Checkbox-${index}`)}
      />
    </div>
  );
}

function TaxCell({
  value, row: { index }, column: { id }, updateMyData
}) {
  const onChange = (tax) => updateMyData(index, id, tax);

  return (
    <FormControl>
      <Select
        id="tax"
        name="tax"
        queryKey="tax-rule"
        placeholder="Select tax"
        value={value}
        onChange={onChange}
        optionsLoader={getTaxRules}
        styles={spreadSheetSelectStyles}
        dataAccessKey="data"
        optionLabelKey="name"
        optionValueKey="id"
        isClearable
        testId={getTestId(`GeneralJournal-${pascalize(id)}-Select-${index}`)}
      />
    </FormControl>
  );
}

function AccountCell({
  value, row: { index }, column: { id }, updateMyData, readOnly
}) {
  const onChange = (account) => updateMyData(index, id, account);
  return (
    <FormControl>
      <Select
        isDisabled={readOnly}
        id="account"
        name="account"
        queryKey="ledger-account"
        placeholder="Select account"
        value={value}
        onChange={onChange}
        optionsLoader={() =>
          getLedgerAccounts().then((res) => {
            res.data = flattenTree(buildTree(res.data, 1));
            res.data = res.data.filter((item) => item.parentId);
            return res;
          })}
        styles={spreadSheetSelectStyles}
        dataAccessKey="data"
        optionLabelKey="name"
        optionValueKey="id"
        formatOption={formatGLAccountOption}
        hasNestedOptions
        isClearable
        testId={getTestId(`GeneralJournal-${pascalize(id)}-Select-${index}`)}
      />
    </FormControl>
  );
}

const costCenterObjectNames = [
  {
    label: "Tenant",
    value: "Tenant",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Supplier",
    value: "Supplier",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Employee",
    value: "Employee",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Building",
    value: "Building",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Component",
    value: "Component",
    optionLabelKey: "name",
    isDynamic: true,
    parentFilters: ["Building"],
  },
  {
    label: "Unit",
    value: "Unit",
    optionLabelKey: "name",
    isDynamic: true,
    parentFilters: ["Building", "Component"],
  },
  {
    label: "Location",
    value: "Location",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Department",
    value: "Organization",
    optionLabelKey: "orgName",
    isDynamic: true,
  },
  {
    label: "Class",
    value: "Class",
    optionLabelKey: "name",
    isDynamic: true,
  },
];

function CostCenterCell({
  value,
  row: { index, original },
  column: { id },
  updateMyData,
  readOnly,
}) {
  const onChange = (option) => updateMyData(index, id, option);

  const objectNames = useMemo(() => {
    const { account } = original;
    if (!account) {
      return costCenterObjectNames;
    }

    if (account.accountType === "AccountsReceivable") {
      return costCenterObjectNames.filter((objectName) => objectName.value !== "Account");
    }

    if (account.accountType === "AccountsPayable") {
      return costCenterObjectNames.filter((objectName) => objectName.value !== "Tenant");
    }

    return costCenterObjectNames.filter(
      (objectName) => !["Tenant", "Account"].includes(objectName.value)
    );
  }, [original.account]);

  return (
    <DynamicObjectSearchSelectField
      name="CostCenter"
      objectNames={objectNames}
      value={value}
      onChange={onChange}
      backspaceRemovesValue
      isClearable
      styles={spreadSheetSelectStyles}
      showLabel={false}
      components={{ IndicatorSeparator }}
      testId={getTestId(`GeneralJournal-${pascalize(id)}-Select-${index}`)}
      disabled={readOnly}
    />
  );
}

function Table({
  columns, data, updateMyData, readOnly
}) {
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, rows
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      readOnly,
    },
    useFlexLayout,
    useResizeColumns
  );

  return (
    <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
              >
                <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? "is-resizing" : ""}`}
                />
                <div className="table-header-cell-content">{column.render("Header")}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="table-row">
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
};

function GeneralJournalEntrySpreadSheet({
  journalEntryData, readOnly, entryData, importedData
}) {
  const navigate = useNavigate();
  const activeCompany = useSelector(selectActiveCompany);
  const [noOfRows, setNoOfRows] = useState(12);
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState({
    label: "AED",
    value: "AED",
  });
  const [entryDate, setEntryDate] = useState(new Date());
  const [costCenter, setCostCenter] = useState(null);
  const dispatch = useDispatch();
  const nextJENumber = useSelector(selectAutoNumber("JournalEntry"));
  const [entryNo, setEntryNo] = useState("");
  const { isOpen, closeModal, openModal } = useModal(false);

  const journalEntryNumber = readOnly ? entryNo : nextJENumber;

  useEffect(() => {
    if (journalEntryData.length > 0) {
      setData(journalEntryData);
    } else {
      setData(getInitialData());
    }
  }, [journalEntryData]);

  const generateNumberMutation = useMutation(getJournalEntryNumber, {
    onError: () => {
      showToast("Could not generate JE number. Please refresh!", "error");
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        dispatch(
          setAutoNumber({
            key: "JournalEntry",
            value: response.id,
          })
        );
      }
    },
  });

  useEffect(() => {
    if (activeCompany) {
      const { defaultCurrencyCode } = activeCompany;

      const currencyCode = {
        label: defaultCurrencyCode,
        value: defaultCurrencyCode,
      };

      setCurrency(currencyCode);
    }
  }, [activeCompany]);

  const resetState = () => {
    const newData = getInitialData();
    setData(() => newData);
    setCostCenter(null);
    navigate(-1);
  };

  const saveMutation = useMutation(createTransfer, {
    onError: () => {
      showToast("Could not save journal entry. Please try again", "error");
    },
    onSuccess: () => {
      showToast("Journal entry saved", "success");
      resetState();
      navigate("/finance/journal-entry");
    },
  });

  useEffect(() => {
    if (!readOnly) {
      generateNumberMutation.mutate();
    } else if (entryData.isClone) {
      setEntryDate(new Date());
      generateNumberMutation.mutate();
    } else {
      setEntryDate(entryData?.date ? new Date(entryData?.date) : "");
      setEntryNo(entryData?.number);
    }
  }, [readOnly, entryData.isClone]);

  useEffect(() => {
    if (importedData.length > 0) {
      setData([...importedData, ...data]);
    }
  }, [importedData]);

  const columns = useMemo(
    () => [
      {
        Header: "Account",
        accessor: "account",
        Cell: AccountCell,
      },
      {
        Header: `Debit (${currency.value})`,
        accessor: "debit",
        Cell: DebitCell,
      },
      {
        Header: `Credit (${currency.value})`,
        accessor: "credit",
        Cell: CreditCell,
      },
      // {
      //   Header: "Tax",
      //   accessor: "tax",
      //   Cell: TaxCell,
      // },
      {
        Header: "Entry For",
        accessor: "costCenter",
        Cell: CostCenterCell,
      },
      {
        Header: "Memo",
        accessor: "memo",
        Cell: TextCell,
      },
      {
        Header: "Billable",
        accessor: "billable",
        Cell: CheckboxCell,
        collapse: true,
      },
    ],
    [currency]
  );

  const totalCredit = formatDecimalValues(
    Number(
      data
        .filter((r) => r.credit)
        .reduce((total, currentValue) => total + Number(currentValue.credit), 0)
    )
  );

  const totalCreditWithTax = formatDecimalValues(
    Number(
      data
        .filter((r) => r.credit)
        .reduce((total, currentValue) => total + Number(currentValue.totalAmount || 0), 0)
    )
  );

  const totalDebit = formatDecimalValues(
    Number(
      data
        .filter((r) => r.debit)
        .reduce((total, currentValue) => total + Number(currentValue.debit), 0)
    )
  );

  const totalDebitWithTax = formatDecimalValues(
    Number(
      data
        .filter((r) => r.debit)
        .reduce((total, currentValue) => total + Number(currentValue.totalAmount || 0), 0)
    )
  );

  const diff = totalDebit - totalCredit;

  const updateMyData = (rowIndex, columnId, value) => {
    const oldData = [...data];
    const row = oldData[rowIndex];
    row[columnId] = value;

    if (columnId === "credit" || columnId === "debit") {
      row.amount = value;
      row.totalAmount = value;
      if (value) {
        row.costCenter = costCenter;
      }
    }

    if (columnId === "account") {
      if (!row.credit && !row.debit) {
        if (diff !== 0) {
          if (diff > 0) {
            row.credit = diff;
            row.amount = row.credit;
            row.totalAmount = row.credit;
          } else {
            row.debit = Math.abs(diff);
            row.amount = row.debit;
            row.totalAmount = row.debit;
          }
        }
      }
    }

    if (columnId === "tax") {
      if (value && (row.credit || row.debit)) {
        const isDebit = Boolean(row.debit);
        const isSaleCollected = value.saleCollected;
        const isPurchaseCollected = value.purchaseCollected;
        const amount = row.amount || row.credit || row.debit;
        const saleTaxAmount = (amount * value.saleRate) / 100;
        const purchaseTaxAmount = (amount * value.purchaseRate) / 100;
        const taxAmount = saleTaxAmount + purchaseTaxAmount;
        const totalAmount = parseInt(amount, 10) + taxAmount;

        row.amount = amount;
        row.taxAmount = taxAmount;
        row.totalAmount = totalAmount;

        if (isDebit) {
          if (isSaleCollected) {
            row.debitTaxAmount = saleTaxAmount;
          }

          if (isPurchaseCollected) {
            row.debitTaxAmount = purchaseTaxAmount;
          }
        } else {
          if (isSaleCollected) {
            row.creditTaxAmount = saleTaxAmount;
          }

          if (isPurchaseCollected) {
            row.creditTaxAmount = purchaseTaxAmount;
          }
        }
      } else if (!value) {
        if (row.credit) {
          row.totalAmount = row.credit;
        } else if (row.debit) {
          row.totalAmount = row.debit;
        }

        row.taxAmount = 0;
        row.debitTaxAmount = 0;
        row.creditTaxAmount = 0;
      }
    }

    setData(oldData);
  };

  const areTransactionsValid = useCallback(() => {
    if (!journalEntryNumber) {
      showToast("Enter a valid entry number", "error");
      return false;
    }

    if (!entryDate) {
      showToast("Select a valid entry date", "error");
      return false;
    }

    if (!currency) {
      showToast("Select a currency for entry", "error");
    }

    if (totalCredit !== totalDebit) {
      showToast("Debit and Credit amounts are not balanced", "error");
      return false;
    }

    if (totalCreditWithTax !== totalDebitWithTax) {
      showToast("Debit and Credit amounts are not balanced", "error");
      return false;
    }

    const rowsWithTransactions = data.filter((row) => row.debit || row.credit);

    const validTransactionsWithAccounts = rowsWithTransactions.filter((row) => row.account);

    if (rowsWithTransactions.length !== validTransactionsWithAccounts.length) {
      showToast("Some transactions are not valid", "error");
      return false;
    }

    const transactionsWithSomeValues = data.filter((row) => row.credit || row.debit || row.account);

    if (rowsWithTransactions.length !== transactionsWithSomeValues.length) {
      showToast("Some transactions are not valid", "error");
      return false;
    }

    const rowsWithCostCenters = rowsWithTransactions.filter(
      (row) => {
        const selectedCostCenterKeys = row?.costCenter ?
          Object.keys(row?.costCenter).filter((key) => row?.costCenter[key]) :
          [];

        return selectedCostCenterKeys.length;
      }
    );

    // TODO: enable the check after balancing entries
    // if (rowsWithTransactions.length !== rowsWithCostCenters.length) {
    //   showToast("Some transactions do not have cost centers selected", "error");
    //   return false;
    // }

    return true;
  }, [journalEntryNumber, entryDate, data]);

  const saveEntry = async (isModalOpen) => {
    if (areTransactionsValid()) {
      if (isModalOpen) {
        openModal();
        return;
      }
      const transfersData = prepareTransfers({
        transactions: data.filter((row) => row.debit || row.credit),
        entryNumber: journalEntryNumber,
      });

      let date;

      if (entryDate) {
        date = entryDate;
      } else {
        date = new Date();
      }

      const formattedDate = formatApiPayloadDate(date);

      if (typeof formattedDate === "string") {
        const response = await checkDateInAccountingPeriod(formattedDate);

        if (response.status !== "Open") {
          showToast("Entry date is outside of the current open accounting period", "error");
          return;
        }
      }

      const payload = {
        type: "AdjustingEntry",
        number: "JE-{yy}-#",
        postedAt: formattedDate,
        transfers: transfersData,
        companyId: activeCompany.id,
        currencyCode: currency.value,
      };
      saveMutation.mutate(payload);
    }
  };

  const rowsWithTaxEntries = data?.filter((r) => r.tax);

  function createObjects(count, originalObject) {
    const objects = [];
    for (let i = 0; i < count; i += 1) {
      objects.push({ ...originalObject });
    }
    return objects;
  }

  const addRows = () => {
    const originalObject = {
      account: null,
      tax: null,
      debit: "",
      credit: "",
      memo: "",
      costCenter: null,
      billable: false,
    };
    const twelveObjects = createObjects(noOfRows, originalObject);
    setData([...data, ...twelveObjects]);
  };

  function updateRowsWithCostCenter(objects, costCenterValue) {
    const updatedObjects = objects.map((obj) => {
      if (obj.debit !== "" || obj.credit !== "") {
        return { ...obj, costCenter: costCenterValue }; // Set your desired cost center value here
      }
      return obj;
    });
    return updatedObjects;
  }

  const updateCostCenter = (value) => {
    setCostCenter(value);
    const updatedData = updateRowsWithCostCenter(data, value);
    setData(updatedData);
  };

  return (
    <div className="spread-sheet">
      <div className="table-wrapper">
        <div className="row my-3 px-4">
          <div className="col-md-3">
            <Select
              isDisabled={readOnly}
              name="currencyCode"
              queryKey="currency"
              placeholder="Select currency"
              value={currency}
              onChange={setCurrency}
              optionsLoader={() => getCurrencies()}
              dataAccessKey="data"
              optionLabelKey="code"
              optionValueKey="code"
              isClearable
              testId="GeneralJournal-CurrencyCode-Select"
            />
          </div>
          <div className="col-md-3">
            <CustomizedDatePicker
              disabled={readOnly}
              value={entryDate}
              onChange={setEntryDate}
              placeholder="Select entry date"
              maxDate={new Date()}
              clearIcon={null}
              testId="GeneralJournal-EntryDate-DatePicker"
            />
          </div>
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={journalEntryNumber}
              placeholder="JE-xx-xxx"
              testId="GeneralJournal-EntryNo-Input"
              disabled
            />
          </div>
          {!readOnly ? (
            <div className="col-md-3 global-search-field">
              <DynamicObjectSearchSelectField
                name="CostCenter"
                objectNames={costCenterObjectNames}
                value={costCenter}
                onChange={updateCostCenter}
                backspaceRemovesValue
                isClearable
                styles={spreadSheetSelectStyles}
                showLabel={false}
                components={{ IndicatorSeparator }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <Table
          readOnly={readOnly}
          key={data}
          columns={columns}
          data={data || []}
          updateMyData={updateMyData}
        />
        {rowsWithTaxEntries?.map((row) => (
          <div className="row total-row">
            <div className="col-md-2">
              <p>{row?.tax?.label}</p>
            </div>
            <div className="col-md-2">
              <p>{row.debitTaxAmount || ""}</p>
            </div>
            <div className="col-md-2">
              <p>{row.creditTaxAmount || ""}</p>
            </div>
          </div>
        ))}
        {!readOnly && (
          <div className="row total-row add-rows">
            <div className="col-md-1">
              <Button small className="btn-with-icon" bordered onClick={() => addRows()}>
                <Button.Prepend>
                  <SvgIcon icon="plus-icon" />
                </Button.Prepend>
                Add
              </Button>
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <Input
                className="input-height-fix"
                value={noOfRows}
                onChange={(e) => setNoOfRows(e)}
                placeholder="0"
                testId="GeneralJournal-addRows-Input"
              />
              <div className="rows-text">
                <span>more rows at the bottom</span>
              </div>
            </div>
          </div>
        )}
        <div className="row total-row">
          <div className="col-md-2">
            <p>Total </p>
          </div>
          <div className="col-md-2">
            <p>
              Debit:
              {formatNumber(Number(totalDebitWithTax))}
            </p>
          </div>
          <div className="col-md-2">
            <p>
              Credit:
              {formatNumber(Number(totalCreditWithTax))}
            </p>
          </div>
        </div>
      </div>
      {!readOnly && (
        <div className="btn-container">
          <Button bordered small onClick={resetState} testId="GeneralJournal-Cancel-Button">
            Cancel
          </Button>
          <Button
            small
            onClick={() => saveEntry(true)}
            testId="GeneralJournal-Submit-Button"
            loading={saveMutation.isLoading}
          >
            Save
          </Button>
        </div>
      )}
      <AlertModal
        title="Are you sure you want to create Journal Entry?"
        subtitle="This action will create a Journal Entry"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={() => {
          saveEntry(false);
          closeModal();
        }}
      />
    </div>
  );
}

export default GeneralJournalEntrySpreadSheet;

GeneralJournalEntrySpreadSheet.propTypes = {
  readOnly: PropTypes.bool,
  journalEntryData: PropTypes.array,
  importedData: PropTypes.array,
  entryData: PropTypes.shape({
    number: PropTypes.string,
    date: PropTypes.string,
  }),
};

GeneralJournalEntrySpreadSheet.defaultProps = {
  readOnly: false,
  journalEntryData: [],
  importedData: [],
  entryData: { number: "", date: "" },
};
